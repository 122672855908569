import React from 'react';
import StyledFormElement from '../../../../../components/StyledFormElement';
import Checkbox from '@paprika/checkbox';
import useI18n from '@paprika/l10n/lib/useI18n';
import useToggle from '../../useToggle';
import Input from '@paprika/input';

export interface AttributeTypeData {
  name: string | null;
  displayName: string | null;
  tooltip: string | null;
  isGlobal: boolean;
  isRequired: boolean;
  isHidden: boolean;
}

interface AttributeTypeNameProps {
  attributeTypeDetails: AttributeTypeData;
  error: string | null;
  isSaving: boolean;
  onChange(attributeTypeDetails: AttributeTypeData): void;
}

function getCheckboxState(property: boolean | null) {
  return property ? Checkbox.types.state.CHECKED : Checkbox.types.state.UNCHECKED;
}

export default function AttributeTypeName({ attributeTypeDetails, error, isSaving, onChange }: AttributeTypeNameProps) {
  const { t } = useI18n();

  const [isToggleDisplayName, setIsToggleDisplayAssetType] = useToggle(!!attributeTypeDetails.displayName);
  const [isToggleTooltip, setIsToggleTooltip] = useToggle(!!attributeTypeDetails.tooltip);

  function handleChange(change: Partial<AttributeTypeData>) {
    const value: AttributeTypeData = {
      ...attributeTypeDetails,
      ...change,
    };
    onChange(value);
  }

  function handleToggleDisplayAssetType(value: string) {
    if (attributeTypeDetails.displayName) {
      handleChange({ displayName: '' });
    }
    setIsToggleDisplayAssetType(value);
  }

  function handleToggleTooltip(value: string) {
    if (attributeTypeDetails.tooltip) {
      handleChange({ tooltip: '' });
    }
    setIsToggleTooltip(value);
  }

  function handleChangeAttributeTypeName(value: string) {
    handleChange({ name: value });
  }
  function handleChangeDisplayName(event: React.FormEvent<HTMLInputElement>) {
    handleChange({ displayName: event.currentTarget.value });
  }
  function handleChangeTooltip(event: React.FormEvent<HTMLInputElement>) {
    handleChange({ tooltip: event.currentTarget.value });
  }
  function handleChangeIsGlobal() {
    handleChange({ isGlobal: !attributeTypeDetails.isGlobal });
  }
  function handleChangeIsRequired() {
    handleChange({ isRequired: !attributeTypeDetails.isRequired });
  }
  function handleChangeIsHidden() {
    handleChange({ isHidden: !attributeTypeDetails.isHidden });
  }

  return (
    <>
      <StyledFormElement
        label={t('attribute_type.label')}
        onChange={handleChangeAttributeTypeName}
        value={attributeTypeDetails.name}
        placeholder={t('attribute_type.placeholder')}
        isSaving={isSaving}
        error={error}
        data-qa-anchor="attribute-type-form-element"
      />

      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggleDisplayName)} onChange={handleToggleDisplayAssetType}>
          {t('attribute_type.display_name.label_checkbox')}
        </Checkbox>
      </div>

      {isToggleDisplayName ? (
        <div className="widget-space">
          <Input
            data-qa-anchor="attribute-type-display-name-input"
            placeholder={t('attribute_type.display_name.placeholder')}
            value={attributeTypeDetails.displayName}
            onChange={handleChangeDisplayName}
          />
        </div>
      ) : null}

      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggleTooltip)} onChange={handleToggleTooltip}>
          {t('attribute_type.tooltip.label_checkbox')}
        </Checkbox>
      </div>

      {isToggleTooltip ? (
        <div className="widget-space">
          <Input
            data-qa-anchor="attribute-type-tooltip-name-input"
            placeholder={t('attribute_type.tooltip.placeholder')}
            value={attributeTypeDetails.tooltip}
            onChange={handleChangeTooltip}
          />
        </div>
      ) : null}

      <div className="widget-space">
        <Checkbox
          checkedState={getCheckboxState(attributeTypeDetails.isGlobal)}
          onChange={handleChangeIsGlobal}
          isDisabled={isSaving}
        >
          {t('attribute_type.is_global.label')}
        </Checkbox>
      </div>
      <div className="widget-space">
        <Checkbox
          checkedState={getCheckboxState(attributeTypeDetails.isRequired)}
          onChange={handleChangeIsRequired}
          isDisabled={isSaving}
        >
          {t('attribute_type.is_required.label')}
        </Checkbox>
      </div>
      <div className="widget-space">
        <Checkbox
          checkedState={getCheckboxState(attributeTypeDetails.isHidden)}
          onChange={handleChangeIsHidden}
          isDisabled={isSaving}
        >
          {t('attribute_type.is_hidden.label')}
        </Checkbox>
      </div>
    </>
  );
}
