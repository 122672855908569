import React from 'react';
import { Route, Link, Redirect, Switch, useParams, useLocation, useHistory } from 'react-router-dom';
import Avatar from '@paprika/card/lib/components/Avatar';
import Button from '@paprika/button';
import Card from '@paprika/card';
import Heading from '@paprika/heading';
import Overlay from '@paprika/overlay';
import Spinner from '@paprika/spinner';
import Toast from '@paprika/toast';
import Tokens from '@paprika/tokens';
import useI18n from '@paprika/l10n/lib/useI18n';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import LabLogoIcon from '@acl-services/wasabicons/lib/LabLogo';
import { ActionTypes, useAppState, useAppStateDispatch } from 'providers/AppStateProvider';
import AssetsApi from 'services/AssetsApi';
import { useOverlayContainerRef } from 'providers/OverlayContextProvider';
import ErrorPage from 'pages/Error/ErrorPage';
import serializer from 'serializers';

import AttributeTypesPage from '../AttributeTypes';
import OverviewPanel from './components/OverviewPanel';
import './AssetType.scss';

export function AssetTypePage({
  id,
  assetTypeName,
  displayName,
  description,
  createdBy,
  updatedBy,
  updatedAt,
  attributeTypesCount,
  isArchived,
  locale,
  onClickRestore,
  serverError,
}: any) {
  const overlayContainerRef = useOverlayContainerRef();
  const { pathname } = useLocation();
  const { t } = useI18n();
  const isNew = id === 'new';
  const headingText = isNew ? t('asset_type.your_new_asset_type') : assetTypeName;

  return (
    <>
      <Overlay
        className="asset-type__overlay"
        container={overlayContainerRef && overlayContainerRef.current}
        isOpen={isArchived}
        zIndex={3}
      >
        {() => (
          <>
            <Toast hasCloseButton={false} kind="locked">
              {t('asset_type.archived_warning', { name: assetTypeName, count: attributeTypesCount })}
              <Button onClick={onClickRestore} size={ShirtSizes.SMALL}>
                {t('actions.restore')}
              </Button>
            </Toast>
            {serverError && <Toast kind="error">{serverError}</Toast>}
          </>
        )}
      </Overlay>
      <Heading level={1} isLight>
        {headingText}
      </Heading>
      <div className="asset-type__flex-wrapper">
        <div className="asset-type__overview-panel-wrapper">
          <Heading level={2}>{t('asset_type.overview')}</Heading>
          <OverviewPanel
            id={id}
            defaultAssetTypeName={assetTypeName}
            defaultDisplayName={displayName}
            defaultDescription={description}
            isNew={isNew}
            locale={locale}
            createdBy={createdBy}
            updatedBy={updatedBy}
            updatedAt={updatedAt}
          />
        </div>
        <div className="asset-type__body-wrapper">
          <Heading level={2}>{t('asset_type.related_items')}</Heading>
          <Link to={`${pathname}/attribute_types`}>
            <Card size={ShirtSizes.SMALL}>
              <Card.Header>
                <Avatar backgroundColor={Tokens.color.blackLighten10} color={Tokens.color.white}>
                  <LabLogoIcon />
                </Avatar>
              </Card.Header>
              <Card.Content>
                <Card.Title>{t('attribute_type.attribute_types')}</Card.Title>
                <Card.Metadata>{t('counters.total', { count: attributeTypesCount })}</Card.Metadata>
              </Card.Content>
            </Card>
          </Link>

          <Heading level={2}>
            Views&nbsp;
            <Button
              onClick={() => {
                alert('Functionality TBD');
              }}
            >
              + {t('actions.add')}
            </Button>
          </Heading>

          <Link to={`${pathname}/views/1`}>
            <Card size="small">
              <Card.Content>
                <Card.Title>View 1 TBD</Card.Title>
                <Card.Metadata>250 attribute types, 100 questions</Card.Metadata>
                <div className="view-card--spacer"></div>
                <Card.Metadata>Created by Homer Simpson</Card.Metadata>
                <Card.Metadata>Last updated by Troy McClure</Card.Metadata>
              </Card.Content>
            </Card>
          </Link>
        </div>
      </div>
    </>
  );
}

export default function AssetTypePageWrapper() {
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const { assetTypeId } = useParams<{ assetTypeId: string }>();
  const { locale } = useI18n();
  const history = useHistory();
  const { pathname } = useLocation();
  const { assetTypes, hasAssetTypes, unArchiveAssetType } = useAppState();
  const dispatch = useAppStateDispatch();
  const isNew = assetTypeId === 'new';
  const [serverError, setServerError] = React.useState('');

  React.useEffect(() => {
    async function fetchAssetType() {
      const response = await AssetsApi.fetch(`/asset_types/${assetTypeId}`);

      // Move this to a hook
      if (!response.ok) {
        switch (response.status) {
          case 403:
            setRedirect('/403');
            break;
          case 404:
            setRedirect('/404');
        }
      }

      const assetTypeJson = await response.json();
      dispatch({
        type: ActionTypes.SET_ASSET_TYPES,
        payload: [serializer.deserialize('asset_types', assetTypeJson)],
      });
    }

    if (!isNew) {
      fetchAssetType();
    }
  }, [assetTypeId, dispatch, isNew]);

  if (!locale || (!hasAssetTypes && !isNew) || assetTypeId === undefined) {
    return <Spinner size={ShirtSizes.MEDIUM} />;
  }

  let name = '',
    displayName = '',
    description = '',
    createdBy = '',
    updatedBy = '',
    updatedAt = '',
    isArchived = false,
    attributeTypesCount = 0;

  if (!isNew && assetTypes[assetTypeId]) {
    ({ name, displayName, description, createdBy, updatedBy, updatedAt, isArchived, attributeTypesCount } =
      assetTypes[assetTypeId]);
  }

  async function handleClickRestore() {
    const response = await unArchiveAssetType(assetTypeId);

    if (response.success) {
      history.push(`${pathname}`);
    }

    if (!response.success) {
      setServerError(response.error);
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Switch>
      <Route exact path="/asset_types/:assetTypeId">
        <AssetTypePage
          id={assetTypeId}
          assetTypeName={name}
          displayName={displayName}
          description={description}
          createdBy={createdBy}
          updatedBy={updatedBy}
          updatedAt={updatedAt}
          attributeTypesCount={attributeTypesCount}
          isArchived={isArchived}
          locale={locale}
          onClickRestore={handleClickRestore}
          serverError={serverError}
          // questionsCount={}
          // views={[]}
        />
      </Route>
      <Route exact path="/asset_types/:assetTypeId/attribute_types/:attributeTypeId?">
        <AttributeTypesPage />
      </Route>
      <Route>
        <ErrorPage headingKey="error.page_not_found" bodyTextKey="error.page_couldnt_be_found" />
      </Route>
    </Switch>
  );
}
