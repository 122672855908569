import { JwtHighbondClient } from '@acl-services/jwt-highbond-client';
import { AppConfig } from 'config';

export default class ApiService {
  private jwtHighbondClient: JwtHighbondClient;
  private apiUrl: string;

  constructor(apiUrl: string) {
    this.jwtHighbondClient = new JwtHighbondClient({
      tokenRefreshUrl: AppConfig.tokenRefreshUrl,
      autoRedirect: true,
    });
    this.apiUrl = apiUrl;
  }
  async fetch(path: string, options: any = {}) {
    let opts = {
      credentials: 'include',
      ...options,
    };
    opts.headers = {
      Accept: 'application/vnd.api+json',
      ...(options.headers || {}),
    };
    if (opts.body) {
      if (typeof opts.body !== 'string') {
        opts.body = JSON.stringify(opts.body);
      }
      opts.headers['Content-Type'] = 'application/vnd.api+json';
    }
    try {
      return await this.jwtHighbondClient.fetchWithTokenRefresh(`${this.apiUrl}${path}`, opts);
    } catch (err) {
      return { ok: false, status: 0, statusText: 'API call failed.' } as Response;
    }
  }
}
