import React from 'react';
import { useLocation } from 'react-router-dom';
import AssetsApi from 'services/AssetsApi';

function Login() {
  const searchParams = new URLSearchParams(useLocation().search);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    (async function () {
      const response = await AssetsApi.fetch('/login');
      if (response.ok) {
        window.location.href = searchParams.get('redirect_uri') || window.location.origin;
      } else {
        setError(`${response.status}: ${response.statusText}`);
      }
    })();
  }, [searchParams]);

  return <div>{error ? <p>{error}</p> : <p>Logging in...</p>}</div>;
}

export default Login;
