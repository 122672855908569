import React from 'react';

export const ErrorPageContext = React.createContext<any>(null);

export function useErrorPage() {
  return React.useContext(ErrorPageContext);
}

export default function ErrorPageContextProvider({ children }: { children: React.ReactNode }) {
  const [isErrorPage, setIsErrorPage] = React.useState(false);

  return <ErrorPageContext.Provider value={{ isErrorPage, setIsErrorPage }}>{children}</ErrorPageContext.Provider>;
}
