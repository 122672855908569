import React from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import BooleanIcon from '@acl-services/wasabicons/lib/Boolean';
import DropdownIcon from '@acl-services/wasabicons/lib/Dropdown';
import LinkIcon from '@acl-services/wasabicons/lib/Link';
import NumberSignIcon from '@acl-services/wasabicons/lib/NumberSign';
import ActionBar, {
  Filter,
  useFilter,
  Sort,
  useSort,
  ColumnsArrangement,
  useColumnsArrangement,
} from '@paprika/action-bar';
import Button from '@paprika/button';
import DataGrid from '@paprika/data-grid';
import Heading from '@paprika/heading';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import Spinner from '@paprika/spinner';
import useI18n from '@paprika/l10n/lib/useI18n';
import { ActionTypes, useAppState, useAppStateDispatch } from 'providers/AppStateProvider';
import AssetsApi from 'services/AssetsApi';
import AttributeType from 'interfaces/AttributeType';
import serializer from 'serializers';

import DataGridHeader from './components/DataGridHeader';
import IsGlobalContent from './components/IsGlobalContent';
import RelatedAssetTypesContent from './components/RelatedAssetTypesContent';
import SidePanel from './components/SidePanel';
import './AttributeTypes.scss';

// TODO: There is a paprika PR to add `isGrow` so columns can have dynamic width; use it on the `Name` column. This is useful for when someone hides a column, the table adapts. (https://github.com/acl-services/paprika/pull/552)
// TODO: Nahum is looking into this bug: if you use they keyboard and open the sidepanel, then exit it, focus should be restored to the cell you were focused on.

interface AttributeTypesProps {
  attributeTypes: AttributeType[];
  name: string;
  pathname: string;
  onClickRow(row: any): void;
}

// TODO: considering moving this to a routes.ts file
function getUrl({ assetTypeId, attributeTypeId }: { assetTypeId: number | string; attributeTypeId: number | string }) {
  return `/asset_types/${assetTypeId}/attribute_types/${attributeTypeId}`;
}

export function AttributeTypes({ attributeTypes, name, pathname, onClickRow }: AttributeTypesProps) {
  const SIDEBAR_WIDTH = 46;
  const APP_MARGIN = 24;
  const dataGridWidth = window.innerWidth - SIDEBAR_WIDTH - APP_MARGIN - APP_MARGIN;
  const nameColumnWidth = dataGridWidth * 0.58;
  const responseTypeColumnWidth = dataGridWidth * 0.15;
  const relatedAssetTypesColumnWidth = dataGridWidth * 0.17;
  const globalColumnWidth = dataGridWidth * 0.1;

  const { t } = useI18n();
  const { assetTypeId } = useParams<{ assetTypeId: string }>();

  const columnsSetting = [
    {
      id: 'name',
      type: 'TEXT',
      label: t('attribute_type.name'),
    },
    {
      id: 'dataType',
      type: 'SINGLE_SELECT',
      label: t('attribute_type.response_type'),
    },
    {
      id: 'relatedAssetTypesCount',
      type: 'NUMBER',
      label: t('attribute_type.related_asset_types'),
    },
    {
      id: 'isGlobal',
      type: 'BOOLEAN',
      label: t('attribute_type.global'),
    },
  ];

  const { filters, filteredData, onDeleteFilter, onChangeFilter, ...filterProps } = useFilter({
    columns: columnsSetting,
    data: attributeTypes,
  });

  const { sortedFields, sortedData, onDeleteSort, onChangeSort, ...sortProps } = useSort({
    columns: columnsSetting,
    data: attributeTypes,
  });

  const { orderedColumnIds, isColumnHidden, ...handlers } = useColumnsArrangement(
    columnsSetting.map((column) => column.id),
  );

  const attributeTypesSubset = React.useMemo(() => {
    return sortedData.filter((item: any) => !!filteredData.find((filteredItem: any) => filteredItem.id === item.id));
  }, [filteredData, sortedData]);

  function renderArrangedColumnDefinitions() {
    const columnDefinitions = {
      name: (
        <DataGrid.ColumnDefinition
          header={() => (
            <DataGridHeader
              Icon={<LinkIcon size="20px" className="data-grid-header__icon" />}
              label={t('attribute_type.name')}
            />
          )}
          headerA11yText={() => t('attribute_type.name')}
          cell={({ row }: any) => <Link to={getUrl({ assetTypeId, attributeTypeId: row.id })}>{row.name}</Link>}
          cellA11yText={({ row }: any) => row.name}
          width={nameColumnWidth}
          onClick={onClickRow}
          onPressEnter={onClickRow}
          onPressSpaceBar={onClickRow}
          key="name"
        />
      ),
      dataType: (
        <DataGrid.ColumnDefinition
          header={() => (
            <DataGridHeader
              Icon={<DropdownIcon size="20px" className="data-grid-header__icon data-grid-header__icon--rotate" />}
              label={t('attribute_type.response_type')}
            />
          )}
          headerA11yText={() => t('attribute_type.response_type')}
          cell="dataType"
          cellA11yText={({ row }: any) => row.dataType}
          width={responseTypeColumnWidth}
          key="dataType"
        />
      ),
      relatedAssetTypesCount: (
        <DataGrid.ColumnDefinition
          header={() => (
            <DataGridHeader
              Icon={<NumberSignIcon size="20px" className="data-grid-header__icon" />}
              label={t('attribute_type.related_asset_types')}
            />
          )}
          headerA11yText={() => t('attribute_type.related_asset_types')}
          cell={({ row }: any) => <RelatedAssetTypesContent count={row.relatedAssetTypesCount} />}
          cellA11yText={({ row }: any) => row.relatedAssetTypesCount}
          width={relatedAssetTypesColumnWidth}
          key="relatedAssetTypesCount"
        />
      ),
      isGlobal: (
        <DataGrid.ColumnDefinition
          header={() => (
            <DataGridHeader
              Icon={<BooleanIcon size="20px" className="data-grid-header__icon" />}
              label={t('attribute_type.global')}
            />
          )}
          headerA11yText={() => t('attribute_type.global')}
          cell={({ row }: any) => <IsGlobalContent isChecked={row.isGlobal} />}
          cellA11yText={({ row }: any) => row.isGlobal}
          width={globalColumnWidth}
          key="isGlobal"
        />
      ),
    };

    return orderedColumnIds.map(
      (orderedColumnId: string) =>
        !isColumnHidden(orderedColumnId) && (columnDefinitions as { [key: string]: any })[orderedColumnId],
    );
  }

  return (
    <div className="attribute-types-page">
      <Heading level={1} isLight>
        {name}: {t('attribute_type.attribute_types')}
        <Button
          kind={Button.Kinds.PRIMARY}
          size={ShirtSizes.LARGE}
          onClick={() => {
            alert("This button's functionality is TBD");
          }}
        >
          + {t('actions.add')}
        </Button>
      </Heading>
      <ActionBar>
        <Filter {...filterProps} columns={columnsSetting}>
          {filters.map((filter: any, index: number) => (
            <Filter.Item
              key={filter.id}
              {...filter}
              index={index}
              onChange={onChangeFilter}
              onDelete={onDeleteFilter}
            />
          ))}
        </Filter>
        <Sort {...sortProps} columns={columnsSetting}>
          {sortedFields.map((field: any, index: number) => (
            <Sort.Field
              key={field.id}
              id={field.id}
              columnId={field.columnId}
              direction={field.direction}
              onChange={onChangeSort}
              onDelete={onDeleteSort}
              isFirst={index === 0}
            />
          ))}
        </Sort>
        <ColumnsArrangement orderedColumnIds={orderedColumnIds} {...handlers}>
          {columnsSetting.map((column) => (
            <ColumnsArrangement.ColumnDefinition
              id={column.id}
              key={column.id}
              label={column.label}
              isHidden={isColumnHidden(column.id)}
            />
          ))}
        </ColumnsArrangement>
      </ActionBar>
      <DataGrid key={orderedColumnIds.join('-')} data={attributeTypesSubset} hasZebraStripes width={dataGridWidth}>
        {renderArrangedColumnDefinitions()}
      </DataGrid>
      <SidePanel />
    </div>
  );
}

export default function AttributeTypesWrapper() {
  const { assetTypeId } = useParams() as { assetTypeId: string };
  const history = useHistory();
  const { pathname } = useLocation();
  const { assetTypes, hasAssetTypes } = useAppState();
  const dispatch = useAppStateDispatch();

  React.useEffect(() => {
    async function fetchAttributeTypes() {
      const response = await AssetsApi.fetch(`/asset_types/${assetTypeId}/attribute_types`);
      const attributeTypeJson = await response.json();
      dispatch({
        type: ActionTypes.SET_ASSET_TYPE_ATTRIBUTE_TYPES,
        payload: { id: assetTypeId, data: serializer.deserialize('attribute_types', attributeTypeJson) },
      });
    }

    fetchAttributeTypes();
  }, [assetTypeId, dispatch]);

  function handleClickRow({ row }: any) {
    history.push(getUrl({ assetTypeId, attributeTypeId: row.id }));
  }

  if (!hasAssetTypes || !assetTypes[assetTypeId] || !assetTypes[assetTypeId].attributeTypes) {
    return <Spinner size={ShirtSizes.MEDIUM} />;
  }

  return (
    <AttributeTypes
      name={assetTypes[assetTypeId].name}
      attributeTypes={assetTypes[assetTypeId].attributeTypes}
      pathname={pathname}
      onClickRow={handleClickRow}
    />
  );
}
