import React from 'react';
import FormElement from '@paprika/form-element';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import ListBox from '@paprika/list-box';
import useI18n from '@paprika/l10n/lib/useI18n';
import * as widgets from './components/Widgets';
import { responseTypes } from '../../types';

export enum enumComponentsType {
  Bool = 'Bool',
  Date = 'Date',
  DateTime = 'DateTime',
  Number = 'Number',
  Paragraph = 'Paragraph',
  Text = 'Text',
  Select = 'Select',
}

const activeComponents = [
  enumComponentsType.Bool,
  enumComponentsType.DateTime,
  enumComponentsType.Number,
  enumComponentsType.Paragraph,
  enumComponentsType.Text,
];

const componentsMap = new Map<string, string>();
Object.keys(enumComponentsType).forEach((k) => componentsMap.set(k, k));

interface ResponseTypeProps {
  isSaving: boolean;
  error: string | null;
  onChange(responseType: responseTypes): void;
}

export default function ResponseType(props: ResponseTypeProps) {
  const { isSaving, error, onChange } = props;
  const [activeResponseType, setActiveResponseType] = React.useState<string>(
    componentsMap.get(enumComponentsType.Text)!,
  );
  const { t } = useI18n();

  function handleChangeListBox(index: number, options: [any]) {
    const active = componentsMap.get(options[index].value) || enumComponentsType.Paragraph;
    setActiveResponseType(active);
  }

  // @ts-ignore
  const Widget = widgets[activeResponseType];

  return (
    <>
      <FormElement label="Response type" size={ShirtSizes.LARGE}>
        <FormElement.Content>
          {({ idForLabel, ariaDescribedBy }: { idForLabel: string; ariaDescribedBy: string }) => (
            <>
              <ListBox onChange={handleChangeListBox}>
                <ListBox.Trigger hasClearButton={false} />
                {Array.from(componentsMap).map(([key]) => {
                  const keyOfComponent = key as keyof typeof enumComponentsType;
                  if (activeComponents.includes(enumComponentsType[keyOfComponent])) {
                    const keyComponent = componentsMap.get(key);
                    const isDefaultSelected = keyComponent === activeResponseType;
                    return (
                      <ListBox.Option defaultIsSelected={isDefaultSelected} value={key}>
                        {t(`attribute_types_details.${keyComponent}`)}
                      </ListBox.Option>
                    );
                  }

                  return null;
                })}
              </ListBox>
              <Widget onChange={onChange} isSaving={isSaving} error={error} />
            </>
          )}
        </FormElement.Content>
      </FormElement>
    </>
  );
}
