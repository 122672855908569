import React from 'react';
import Button from '@paprika/button';
import useI18n from '@paprika/l10n/lib/useI18n';
import './AddAssetType.scss';

export default function AddAssetType() {
  const { t } = useI18n();

  return (
    <Button className="attribute-types__footer-buttons__add-asset-type" kind="primary" size="large">
      {t('attribute_type.add_to_asset_type')}
    </Button>
  );
}
