import React from 'react';
import './RelatedAssetTypesContent.scss';

interface RelatedAssetTypesContentProps {
  count: number;
}

export default function RelatedAssetTypesContent({ count }: RelatedAssetTypesContentProps) {
  return <div className="asset-type-attribute-types-page__related-asset-types-content">{count}</div>;
}
