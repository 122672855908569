import React from 'react';
import Button from '@paprika/button';
import Checkbox from '@paprika/checkbox';
import Input from '@paprika/input';
import ListBox from '@paprika/list-box';
import Sortable from '@paprika/sortable';
import ParagraphWidget, { ParagraphProps } from './Paragraph';
import NumberWidget, { NumberProps } from './Number';
import TextWidget, { TextProps } from './Text';
import DateTimeWidget, { DateTimeProps } from './DateTime';
import BoolWidget, { BoolProps } from './Bool';

import useToggle from '../../../../useToggle';

import './Widgets.scss';

export function Paragraph(props: ParagraphProps) {
  return <ParagraphWidget {...props} />;
}

export function Bool(props: BoolProps) {
  return <BoolWidget {...props} />;
}

export function Date() {
  const [isToggleFirst, handleToggleFirst] = useToggle();

  return (
    <>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggleFirst)} onChange={handleToggleFirst}>
          Set default value
        </Checkbox>
      </div>
      <div className="widget-space">
        {isToggleFirst ? (
          <ListBox>
            <ListBox.Trigger hasClearButton={false} />
            <ListBox.Option defaultIsSelected>Current Date</ListBox.Option>
          </ListBox>
        ) : null}
      </div>
    </>
  );
}

export function DateTime(props: DateTimeProps) {
  return <DateTimeWidget {...props} />;
}

export function Number(props: NumberProps) {
  return <NumberWidget {...props} />;
}

export function Text(props: TextProps) {
  return <TextWidget {...props} />;
}

export function Select() {
  const [options] = React.useState([
    { id: 1, label: 'Very low' },
    { id: 2, label: 'Low' },
    { id: 3, label: 'Medium' },
    { id: 4, label: 'High' },
    { id: 5, label: 'Critical' },
  ]);

  const [isToggleFirst, handleToggleFirst] = useToggle();

  // there is a bug with sidePanel and sortable will be fix later
  // https://github.com/acl-services/paprika/issues/603
  return (
    <>
      <div className="widget-space">
        <Checkbox>Allow multiple selections</Checkbox>
      </div>
      <div className="widget-space">
        <Sortable onChange={() => null} onRemove={() => null}>
          {options.map((option) => (
            <Sortable.Item key={option.label} sortId={option.label} data-qa-id={option.label}>
              <Input defaultValue={option.label} /> <Input defaultValue="" />
            </Sortable.Item>
          ))}
        </Sortable>
      </div>
      <div className="widget-space">
        <Button kind="link">+ add option</Button>
      </div>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggleFirst)} onChange={handleToggleFirst}>
          Set default value
        </Checkbox>
      </div>
      {isToggleFirst ? (
        <div className="widget-space">
          <ListBox>
            {options.map((option) => (
              <ListBox.Option key={option.label} sortId={option.label} data-qa-id={option.label}>
                {option.label}
              </ListBox.Option>
            ))}
          </ListBox>
        </div>
      ) : null}
    </>
  );
}
