import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@paprika/button';
import Checkbox from '@paprika/checkbox';
import Switch from '@paprika/switch';
import FormElement from '@paprika/form-element';
import ArrowLeft from '@paprika/icon/lib/ArrowLeft';
import useI18n from '@paprika/l10n/lib/useI18n';
import AssetsApi from 'services/AssetsApi';
import serializer from 'serializers';
import { Select as AttributeSelect } from '@acl-services/sriracha-attribute-type-associator';
import '@acl-services/sriracha-attribute-type-associator/dist/AttributeTypeAssociator.scss';

import './AttributeType.scss';

const { CHECKED, UNCHECKED } = Checkbox.types.state;

const initialSelectAttributeData = {
  options: [{ name: '', weight: '', color: '' }],
  isMultiple: false,
  hasDefaultValue: false,
  defaultValue: [],
};

export const generateAttributeType = (attributeType: any): any => {
  const data = {
    ...attributeType,
    typeOptions: {
      ...attributeType.typeOptions,
    },
    isGlobal: attributeType.isGlobal === CHECKED,
    isRequired: attributeType.isRequired === CHECKED,
  };
  // Workaround for AttributeSelect component that returns empty string for weight and color
  // even if only name has been entered. For score returns empty string only if score has been
  // added and then removed
  if (attributeType.dataType === 'select') {
    data.typeOptions.selectValues.forEach((selectValue: any) => {
      ['weight', 'score', 'color'].forEach((attr) => {
        if (!selectValue[attr]) {
          selectValue[attr] = null;
        }
      });
    });
  }
  return data;
};

function AttributeType() {
  const { attributeTypeId } = useParams<{ attributeTypeId: string }>();
  const [attributeType, setAttributeType] = React.useState<any>({
    typeOptions: {},
    isGlobal: UNCHECKED,
    isRequired: UNCHECKED,
  });
  const [error, setError] = React.useState('');
  const [originalSelectValues, setOriginalSelectValues] = React.useState([]);
  const [selectData, setSelectData] = React.useState(initialSelectAttributeData);
  const I18n = useI18n();

  const updateSelectValues = (attributeTypeData: any) => {
    if (attributeTypeData.dataType !== 'select') {
      return;
    }
    if (attributeTypeData.typeOptions?.selectValues) {
      const selectValues = attributeTypeData.typeOptions.selectValues.sort((a: any, b: any) =>
        a.sortOrder > b.sortOrder ? 1 : a.sortOrder === b.sortOrder ? (a.id > b.id ? 1 : -1) : -1,
      );
      const defaultValue = selectValues.find((selectValue: any) => selectValue.default === true);
      let newSelectData = {
        ...initialSelectAttributeData,
        ...{ options: selectValues },
      };
      if (defaultValue) {
        newSelectData.defaultValue = [defaultValue] as any;
        newSelectData.hasDefaultValue = true;
      }
      setSelectData(newSelectData);
    }
    setOriginalSelectValues(attributeTypeData.typeOptions.selectValues);
  };

  React.useEffect(() => {
    (async function () {
      let response = await AssetsApi.fetch(`/attribute_types/${attributeTypeId}`, {
        method: 'GET',
      });

      if (response.ok) {
        const attributeTypeData = serializer.deserialize('attribute_types', await response.json());
        updateSelectValues(attributeTypeData);
        setAttributeType({
          ...attributeTypeData,
          isGlobal: attributeTypeData.isGlobal ? CHECKED : UNCHECKED,
          isRequired: attributeTypeData.isRequired ? CHECKED : UNCHECKED,
        });
      } else {
        setError(`${response.status}: ${response.statusText}`);
      }
    })();
  }, [attributeTypeId, setOriginalSelectValues]);

  const updateAttributeTypeDetails = async () => {
    let body = generateAttributeType(attributeType);
    const result = await AssetsApi.fetch(`/attribute_types/${attributeTypeId}`, {
      method: 'PATCH',
      body: serializer.serialize('attribute_types', body),
    });
    if (result.ok) {
      const attributeTypeData = serializer.deserialize('attribute_types', await result.json());
      updateSelectValues(attributeTypeData);
      setAttributeType({
        ...attributeTypeData,
        isGlobal: attributeTypeData.isGlobal ? CHECKED : UNCHECKED,
        isRequired: attributeTypeData.isRequired ? CHECKED : UNCHECKED,
      });
    } else {
      setError(`${result.status}: ${result.statusText}`);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const targetKey = event.target.name;
    setAttributeType((prevState: any) => ({
      ...prevState,
      [targetKey]: newValue,
    }));
  };

  const onCheckboxChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttributeType((prevState: Record<string, unknown>) => ({
      ...prevState,
      [property]: attributeType[property] === CHECKED ? UNCHECKED : CHECKED,
    }));
  };

  const onOptionInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string | number = event.target.value;
    const targetKey = event.target.name;
    if (event.target.type === 'number') {
      newValue = Number(newValue);
    }
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        [targetKey]: newValue,
      },
    }));
  };

  const onOptionSwitchChange = (targetKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldValue = attributeType.typeOptions[targetKey];
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        [targetKey]: !oldValue,
      },
    }));
  };

  const onOptionInputListChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string = event.target.value;
    const targetKey = event.target.name;
    let listOfValues = newValue.split(',').map((val) => val.trim());
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        [targetKey]: listOfValues,
      },
    }));
  };

  const onDefaultOptionInputListChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string = event.target.value;
    const targetKey = event.target.name;
    let listOfValues = newValue.split(',').map((val) => val.trim());
    setAttributeType((prevState: any) => {
      let newDefaults = { ...prevState.typeOptions.default, [targetKey]: listOfValues };
      return {
        ...prevState,
        typeOptions: {
          ...prevState.typeOptions,
          default: newDefaults,
        },
      };
    });
  };

  const renderDefaultValueInput = (type?: string) => (
    <FormElement label="Default">
      <FormElement.Content>
        {({ idForLabel }: { idForLabel: string }) => (
          <input
            id={idForLabel}
            name="default"
            value={attributeType.typeOptions.default}
            type={type || 'text'}
            onChange={onOptionInputChange}
          />
        )}
      </FormElement.Content>
    </FormElement>
  );

  const renderSwitchInput = (property: string) => (
    <FormElement label={property.charAt(0).toLowerCase() + property.slice(1)} isInline>
      <FormElement.Content>
        {({ idForLabel }: { idForLabel: string }) => (
          <>
            <Switch
              id={idForLabel}
              isChecked={attributeType.typeOptions[property]}
              onChange={onOptionSwitchChange(property)}
            />
            <span className="switch-description">{attributeType.typeOptions[property] ? 'True' : 'False'}</span>
          </>
        )}
      </FormElement.Content>
    </FormElement>
  );

  const onSelectAttributesChange = React.useCallback(
    (newState) => {
      setSelectData(newState);
      const defaultValue = JSON.stringify(newState.defaultValue?.[0]);
      const selectValueIds: string[] = [];
      const selectValues = newState.options.map((option: any, index: number) => {
        const sortOrder = index + 1;
        selectValueIds.push(option.id);
        return {
          ...option,
          sortOrder,
          default: defaultValue === JSON.stringify(option),
        };
      });
      originalSelectValues.forEach((selectValue: any) => {
        if (selectValue.id && selectValueIds.indexOf(selectValue.id) === -1) {
          selectValues.push({ ...selectValue, ...{ removed: true } });
        }
      });
      setAttributeType((prevState: any) => ({
        ...prevState,
        typeOptions: {
          selectValues,
        },
      }));
    },
    [originalSelectValues],
  );

  const renderDataTypeOptions = (dataType: string) => {
    switch (dataType) {
      case 'boolean':
        return (
          <>
            <h3>Boolean Options</h3>
            {renderSwitchInput('default')}
          </>
        );
      case 'dateTime':
        return (
          <>
            <h3>Date Time Options</h3>
            {renderDefaultValueInput(attributeType.typeOptions.is24Hour ? 'datetime-local' : 'date')}
            {renderSwitchInput('is24Hour')}
          </>
        );
      case 'number':
        return (
          <>
            <h3>Number Options</h3>
            {renderDefaultValueInput('number')}
            {renderSwitchInput('isDecimal')}
          </>
        );
      case 'paragraph':
        return (
          <>
            <h3>Paragraph Options</h3>
            <FormElement label="Default">
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <textarea
                    id={idForLabel}
                    name="default"
                    value={attributeType.typeOptions.default}
                    onChange={onOptionInputChange}
                  />
                )}
              </FormElement.Content>
            </FormElement>
            {renderSwitchInput('isRichText')}
          </>
        );
      case 'text':
        return (
          <>
            <h5>Text Options</h5>
            {renderDefaultValueInput()}
          </>
        );
      case 'select':
        return (
          <div className="sriracha">
            <AttributeSelect data={selectData} onChange={onSelectAttributesChange} />
          </div>
        );
      case 'userLookup':
        return (
          <>
            <h3>User Lookup Options</h3>
            <FormElement label="Default User IDs (Comma Delimited)">
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <textarea
                    id={idForLabel}
                    name="userIds"
                    value={attributeType.typeOptions.default.userIds}
                    onChange={onDefaultOptionInputListChange}
                  />
                )}
              </FormElement.Content>
            </FormElement>
            <FormElement label="Role IDs (Comma Delimited)">
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <textarea
                    id={idForLabel}
                    name="roles"
                    value={attributeType.typeOptions.roles}
                    onChange={onOptionInputListChange}
                  />
                )}
              </FormElement.Content>
            </FormElement>
            {renderSwitchInput('isMultiSelect')}
            {renderSwitchInput('isSelectUser')}
            {renderSwitchInput('isSelectGroup')}
          </>
        );
      default:
        return;
    }
  };

  return (
    <div className="horrendous-ui-wrapper">
      {attributeType && !error ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Button.Link href="/horrendous-ui" isOpenNewTab={false}>
            <ArrowLeft />
            {I18n.t('actions.back')}
          </Button.Link>
          <br />
          <table>
            <thead>
              <tr>
                <th>Field ID</th>
                <th>Global</th>
                <th>Field Name</th>
                <th>Name</th>
                <th>Display Name</th>
                <th>Data Type</th>
                <th>Type Options</th>
                <th>Required</th>
                <th>Tooltip</th>
                <th>Created</th>
                <th>Updated</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr key={attributeType.fieldName}>
                <td>{attributeType.id}</td>
                <td>{attributeType.isGlobal === true || attributeType.isGlobal === CHECKED ? 'true' : 'false'}</td>
                <td>{attributeType.fieldName}</td>
                <td>{attributeType.name}</td>
                <td>{attributeType.displayName}</td>
                <td>{attributeType.dataType}</td>
                <td>{JSON.stringify(attributeType.typeOptions, null, ' ')}</td>
                <td>{attributeType.isRequired === true || attributeType.isRequired === CHECKED ? 'true' : 'false'}</td>
                <td>{attributeType.tooltip}</td>
                <td>
                  {attributeType.createdBy}
                  <br />
                  <small>{attributeType.createdAt}</small>
                </td>
                <td>
                  {attributeType.updatedBy}
                  <br />
                  <small>{attributeType.updatedAt}</small>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style={{ display: attributeType.isMetadata === true ? 'none' : 'block' }}>
            <h3>Actions:</h3>
            <p>Type your changes to see a preview, press update to save your changes.</p>
            <br />
            {['DisplayName', 'Name', 'Tooltip'].map((key: string) => (
              <FormElement label={key} key={key} isInline>
                <FormElement.Content>
                  {({ idForLabel }: { idForLabel: string }) => (
                    <input id={idForLabel} name={key.charAt(0).toLowerCase() + key.slice(1)} onChange={onInputChange} />
                  )}
                </FormElement.Content>
              </FormElement>
            ))}
            <br />
            <Checkbox onChange={onCheckboxChange('isGlobal')} checkedState={attributeType.isGlobal}>
              Global
            </Checkbox>
            <Checkbox onChange={onCheckboxChange('isRequired')} checkedState={attributeType.isRequired}>
              Required
            </Checkbox>
            {attributeType.dataType && (
              <div className="type-options-panel">{renderDataTypeOptions(attributeType.dataType)}</div>
            )}
            <Button kind="primary" onClick={updateAttributeTypeDetails}>
              Update
            </Button>
          </div>
          <br />
          <div className="json-view">
            <hr />
            <strong>Attribute Type as JSON Object:</strong>
            <br />
            {JSON.stringify(attributeType, null, ' ')}
          </div>
        </div>
      ) : error ? (
        error
      ) : (
        `Loading Asset Type ${attributeTypeId}...`
      )}
    </div>
  );
}

export default AttributeType;
