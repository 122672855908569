import React from 'react';
import useI18n from '@paprika/l10n/lib/useI18n';
import LinkCard from 'components/LinkCard';
import { AppsType } from 'lib/customPropTypes';

import './Dashboard.scss';

function Dashboard(props: AppsType) {
  const I18n = useI18n();
  const { apps } = props;

  return (
    <div className="dashboard-container">
      <h1 data-qa-anchor="dashboard-title">{I18n.t('titles.dashboard')}</h1>
      <div className="dashboard-apps-section">
        {apps.map((app) => (
          <LinkCard key={app.url} title={app.title} count={app.count} url={app.url} icon={app.icon} />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
