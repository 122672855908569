import React from 'react';
import ArchiveButton from './components/ArchiveButton/ArchiveButton';
import SaveCancelButton from './components/SaveCancelButton/SaveCancelButton';
import RemoveAssetType from './components/RemoveAssetType/RemoveAssetType';
import AddAssetType from './components/AddAssetType/AddAssetType';

import './FooterButtons.scss';

export default function FooterButtons({
  isDirty,
  isRelated,
  setIsDirty,
}: {
  isDirty: boolean;
  isRelated: boolean;
  setIsDirty: (isDirty: boolean) => void;
}) {
  if (isDirty) {
    return (
      <div className="attribute-types__footer-buttons">
        <SaveCancelButton setIsDirty={setIsDirty} />
        <ArchiveButton />
      </div>
    );
  }

  return (
    <div className="attribute-types__footer-buttons">
      {isRelated ? <RemoveAssetType /> : <AddAssetType />}
      <ArchiveButton />
    </div>
  );
}
