import React from 'react';
import Card from '@paprika/card';
import Heading from '@paprika/heading';
import useI18n from '@paprika/l10n/lib/useI18n';
import { useErrorPage } from 'providers/ErrorPageProvider';
import { ReactComponent as Logo } from './highbond-logo.svg';
import './ErrorPage.scss';

interface ErrorProps {
  headingKey: string;
  bodyTextKey: string;
}

// TODO: translate links in yml file
const systemHomepage = '/login';
const contactSupportTeam = 'https://community.wegalvanize.com/s/contactsupport?language=en_US';
const visitOurSupportPage = 'https://community.wegalvanize.com/s/contactsupport?language=en_US';

export default function ErrorPage({ headingKey, bodyTextKey }: ErrorProps) {
  const { t } = useI18n();
  const { setIsErrorPage } = useErrorPage();
  const bodyMessage = `${t(bodyTextKey)} ${t('error.further_assistance', {
    systemHomepage,
    contactSupportTeam,
  })}`;

  React.useEffect(() => {
    setIsErrorPage(true);

    return () => {
      setIsErrorPage(false);
    };
  }, [setIsErrorPage]);

  return (
    <div className="error-page-wrapper">
      <Logo data-qa-anchor="error-page-logo" className="error-page-logo" />
      <Card className="error-page_card-wrapper">
        <Heading level={1}>{t(headingKey)}</Heading>
        <p
          data-qa-anchor="error-page_bodyMessage"
          dangerouslySetInnerHTML={{
            __html: bodyMessage,
          }}
        ></p>
      </Card>
      <p
        className="error-page-footer"
        dangerouslySetInnerHTML={{
          __html: t('error.further_information', {
            visitOurSupportPage,
          }),
        }}
      ></p>
    </div>
  );
}
