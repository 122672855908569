import React from 'react';
import Button from '@paprika/button';
import AddIcon from '@paprika/icon/lib/Add';
import Input from '@paprika/input';
import Sortable from '@paprika/sortable';

import './AdHocRelationshipLabelCreator.scss';

enum LabelType {
  Upstream = 'upstream',
  Downstream = 'downstream',
  Parallel = 'parallel',
}

const LABEL_TYPE_INDEX = {
  parallel: 0,
  upstream: 0,
  downstream: 1,
};

export type Label = {
  name: string;
  type: LabelType;
  relatedLabel?: string;
};

type LabelRow = {
  id: number;
  labels: Label[];
};

type Props = {
  onChange(labels: Label[]): void;
};

export default function AdHocRelationshipLabelCreator(props: Props) {
  const { onChange } = props;

  const [labelState, setLabelState] = React.useState<{
    labelRows: LabelRow[];
    lastLabelRowId: number;
  }>({
    labelRows: [],
    lastLabelRowId: 0,
  });

  React.useEffect(() => {
    const labels = labelState.labelRows.reduce((collection: Label[], labelRow) => {
      collection.push(...labelRow.labels);
      return collection;
    }, []);
    onChange(labels);
  }, [labelState]);

  const createlabelInput = (rowIndex: number, labelType: LabelType) => (
    <Input
      placeholder={`${labelType} label`}
      key={`input-${labelType}`}
      data-qa-anchor={`ad-hoc-input-${rowIndex}-${labelType}`}
      onChange={(event: any) => handleLabelChange(rowIndex, labelType, event ? event.target.value : '')}
    />
  );

  const createLabelRows = (labelRows: LabelRow[]) => {
    return labelRows.map((labelRow, rowIndex) => {
      return (
        <Sortable.Item sortId={labelRow.id} key={labelRow.id}>
          <div className="label-row">
            {labelRow.labels.length === 1
              ? createlabelInput(rowIndex, LabelType.Parallel)
              : [LabelType.Upstream, LabelType.Downstream].map((labelType) => createlabelInput(rowIndex, labelType))}
          </div>
        </Sortable.Item>
      );
    });
  };

  const labelRows = React.useMemo(() => createLabelRows(labelState.labelRows), [labelState]);

  const handleLabelChange = (labelRowIndex: number, labelType: LabelType, name: string) => {
    const labelIndex = LABEL_TYPE_INDEX[labelType];
    const newLabelRows = [...labelState.labelRows];
    const newRowLabels = [...newLabelRows[labelRowIndex].labels];

    const currentLabel = newRowLabels[labelIndex];
    const newLabel = { ...currentLabel, name };
    newRowLabels[labelIndex] = newLabel;

    if (labelType !== LabelType.Parallel) {
      const relatedLabelIndex =
        currentLabel.type === LabelType.Upstream
          ? LABEL_TYPE_INDEX[LabelType.Downstream]
          : LABEL_TYPE_INDEX[LabelType.Upstream];

      const newRelatedLabel = { ...newRowLabels[relatedLabelIndex], relatedLabel: name };
      newRowLabels[relatedLabelIndex] = newRelatedLabel;
    }

    const newLabelRow = { ...labelState.labelRows[labelRowIndex], labels: newRowLabels };
    newLabelRows[labelRowIndex] = newLabelRow;

    setLabelState({ ...labelState, labelRows: newLabelRows });
  };

  const createNewLabelRow = (isParallelLabel: boolean) => {
    const { labelRows, lastLabelRowId } = labelState;
    const newRowId = lastLabelRowId + 1;

    const labels = isParallelLabel
      ? [
          {
            name: '',
            type: LabelType.Parallel,
          },
        ]
      : [
          {
            name: '',
            type: LabelType.Upstream,
            relatedLabel: '',
          },
          {
            name: '',
            type: LabelType.Downstream,
            relatedLabel: '',
          },
        ];

    setLabelState({
      labelRows: [...labelRows, { labels, id: newRowId }],
      lastLabelRowId: newRowId,
    });
  };

  const handleRemoveLabelRow = (index: number) => {
    const prunedLabelRows = [...labelState.labelRows];
    prunedLabelRows.splice(index, 1);

    setLabelState({ ...labelState, labelRows: prunedLabelRows });
  };

  const handleSortLabelRow = (result: { source: number; destination: number }) => {
    const { source, destination } = result;
    if (destination === null || source === destination) return;

    const reorderedLabelRows = [...labelState.labelRows];
    const movedRow = reorderedLabelRows.splice(source, 1);
    reorderedLabelRows.splice(destination, 0, ...movedRow);

    setLabelState({ ...labelState, labelRows: reorderedLabelRows });
  };

  return (
    <>
      <div>
        <div className="add-label-controls">
          <Button icon={<AddIcon />} onClick={() => createNewLabelRow(true)}>
            Parallel Label
          </Button>
          <Button icon={<AddIcon />} onClick={() => createNewLabelRow(false)}>
            Upstream/Downstream Label
          </Button>
        </div>
        <Sortable onChange={handleSortLabelRow} onRemove={handleRemoveLabelRow}>
          {labelRows}
        </Sortable>
      </div>
    </>
  );
}
