import React from 'react';
import FormElement from '@paprika/form-element';
import Input from '@paprika/input';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import FormElementContentProps from 'interfaces/FormElementContentProps';

import './StyledFormElement.scss';

export default function StyledFormElement({
  label,
  onChange,
  placeholder,
  isSaving,
  error,
  dataTestId,
  ...moreProps
}: any) {
  return (
    <FormElement className="styled-form-element" label={label} size={ShirtSizes.LARGE}>
      <FormElement.Content>
        {({ idForLabel, ariaDescribedBy }: FormElementContentProps) => (
          <Input
            id={idForLabel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            placeholder={placeholder}
            aria-describedby={ariaDescribedBy}
            aria-required
            size={ShirtSizes.LARGE}
            isDisabled={isSaving}
            hasError={!!error}
            data-qa-anchor={dataTestId}
            {...moreProps}
          />
        )}
      </FormElement.Content>
      {error && <FormElement.Error>{error}</FormElement.Error>}
    </FormElement>
  );
}
