import React from 'react';
import { AppsType } from 'lib/customPropTypes';

import { useErrorPage } from 'providers/ErrorPageProvider';
import SideNavigation from './SideNavigation';
import './SideBar.scss';

function SideBar(props: AppsType) {
  const { apps } = props;
  const { isErrorPage } = useErrorPage();

  if (isErrorPage) return null;

  return (
    <div className="master-library__side-bar">
      <SideNavigation apps={apps} />
      {apps.map((app) => (
        <a href={app.url} className="side-bar__menu-item" key={app.url}>
          {app.icon}
        </a>
      ))}
    </div>
  );
}

export default SideBar;
