import { AppConfig } from 'config';

export default class LaunchpadApiService {
  async getGlobalNavBar(orgId: string) {
    const response = await fetch(`${AppConfig.launchpadApiUrl}/global_nav_bar?org_id=${orgId}`, {
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      window.location.href = `${AppConfig.loginUrl}?redirect_uri=${window.location.href}`;
    } else {
      // TODO:
    }
  }
}
