import React from 'react';
import ListBox from '@paprika/list-box';
import DatePicker from '@paprika/date-picker';
import TimePicker from '@paprika/time-picker';
import Checkbox from '@paprika/checkbox';
import useI18n from '@paprika/l10n/lib/useI18n';
import moment from 'moment';

import useToggle from '../../../../../useToggle';
import { dateTime } from '../../../../../types';
import './DateTime.scss';

interface TimePicker {
  error: string;
  hh: number;
  isVisible: boolean;
  mm: number;
  period: string;
  timeStr: string;
  timeStrWithSpace: string;
  value: string;
}

type responseDateTimeType = { date: string | null; time: string | null };

const DATE_FORMAT = 'YYYY-MM-DD';
const CURRENT = 'current'; // this can change later to whatever is decided.

export interface DateTimeProps {
  onChange(type: dateTime): void;
  defaultIs24Hour: boolean;
  defaultValue: string;
}

function dateNowStr() {
  return moment().format(DATE_FORMAT);
}

function timeNowStr() {
  return moment().format('HH:MM:SS');
}

function getSpecificDateTime(dateTimeState: responseDateTimeType) {
  return `${dateTimeState.date} ${dateTimeState.time}`;
}

export default function DateTime({ onChange, defaultValue, defaultIs24Hour }: DateTimeProps) {
  const i18n = useI18n();
  const [is24Hour, setIs24Hour] = useToggle(defaultIs24Hour);
  const [isToggleSetDefault, setIsToggleSetDefault] = useToggle(Boolean(defaultValue));
  const [hasSpecificTime, setHasSpecificTime] = React.useState(() => {
    return defaultValue === CURRENT ? false : Boolean(defaultValue);
  });

  const [datePickerValue, setDatePickerValue] = React.useState<moment.Moment | string | null>(() => {
    moment.locale(i18n.locale);
    if (defaultValue === 'current') return moment();
    return moment(defaultValue, DATE_FORMAT) || moment();
  });
  const [dateTimeState, setDateTimeState] = React.useState<responseDateTimeType>({
    date: null,
    time: null,
  });
  const [value, setValue] = React.useState(defaultValue);

  function handleChangeSetDefaultListBox(index: number, options: [{ value: string }]) {
    if (options[index].value === 'specific') {
      setHasSpecificTime(true);
      if (dateTimeState.date === null && dateTimeState.time === null) {
        setDateTimeState((prev) => {
          return {
            date: dateNowStr(),
            time: timeNowStr(),
          };
        });
      }
      return;
    }

    setValue(CURRENT);
    setHasSpecificTime(false);
  }

  function handleDatePicker(value: moment.Moment | null) {
    setDatePickerValue(value);

    if (value) {
      const newDateString = value.format(DATE_FORMAT);
      setDateTimeState((prev) => {
        return {
          ...prev,
          date: newDateString,
        };
      });
      handleChangeSpecificTime({ date: newDateString, time: dateTimeState.time });
      return;
    }

    setDateTimeState((prev) => {
      return {
        ...prev,
        date: null,
      };
    });
  }

  function handleDatePickerError() {
    setDatePickerValue(null);
    setDateTimeState((prev) => {
      return {
        ...prev,
        date: null,
      };
    });
  }

  function handleTimePicker(value: TimePicker) {
    if (value === null || value.error === 'invalid time pattern') {
      setDateTimeState((prev) => {
        return {
          ...prev,
          time: null,
        };
      });
      return;
    }

    const nextTime = moment(value.timeStrWithSpace, ['hh:mm a']).format('HH:mm:SS');

    setDateTimeState((prev) => {
      return {
        ...prev,
        time: nextTime === 'Invalid date' ? null : nextTime,
      };
    });
    handleChangeSpecificTime({ date: dateTimeState.date, time: nextTime });
  }

  function handleChangeSpecificTime({ date, time }: responseDateTimeType) {
    const response: dateTime = {
      default: value,
      is24Hour: Boolean(is24Hour),
      type: 'dateTime',
    };

    if (date && time) {
      response.default = getSpecificDateTime({ date, time });
      onChange(response);
      return;
    }

    // has an invalid datetime
    response.default = undefined;
    onChange(response);

    response.default = value;
    onChange(response);
  }

  function handleIs24Hour(toggleValue: any) {
    const response: dateTime = {
      default: value,
      is24Hour: Boolean(!is24Hour),
      type: 'dateTime',
    };

    setIs24Hour(toggleValue);
    onChange(response);
  }

  function handleToggleSetDefault(toggleValue: any) {
    setIsToggleSetDefault(toggleValue);

    const response: dateTime = {
      default: undefined,
      is24Hour: Boolean(is24Hour),
      type: 'dateTime',
    };

    if (!isToggleSetDefault) {
      let nextValue =
        hasSpecificTime && dateTimeState.date && dateTimeState.time ? getSpecificDateTime(dateTimeState) : CURRENT;
      response.default = nextValue;
      onChange(response);
      return;
    }

    onChange(response);
  }

  React.useEffect(() => {
    const response: dateTime = {
      default: undefined,
      is24Hour: Boolean(defaultIs24Hour),
      type: 'dateTime',
    };
    onChange(response);
  }, [defaultIs24Hour, onChange]);

  return (
    <>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(is24Hour)} onChange={handleIs24Hour}>
          <Checkbox.Input data-qa-anchor="widget-space-display-checkbox" />
          {i18n.t('attribute_types_details.widgets.display_24_hour_time')}
        </Checkbox>
      </div>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggleSetDefault)} onChange={handleToggleSetDefault}>
          {i18n.t('attribute_types_details.widgets.set_default_values')}
        </Checkbox>
      </div>

      {isToggleSetDefault ? (
        <div className="widget-space">
          <ListBox onChange={handleChangeSetDefaultListBox}>
            <ListBox.Trigger hasClearButton={false} />
            <ListBox.Option value="user_time" defaultIsSelected={hasSpecificTime ? false : true}>
              {i18n.t('attribute_types_details.widgets.users_current_date_time')}
            </ListBox.Option>
            <ListBox.Option value="specific" defaultIsSelected={hasSpecificTime}>
              {i18n.t('attribute_types_details.widgets.specific_date_time')}
            </ListBox.Option>
          </ListBox>
          {hasSpecificTime ? (
            <div className="widgets widget-space">
              <DatePicker
                date={datePickerValue}
                dateFormat="MM/DD/YYYY"
                humanFormat="MMMM DD, YYYY"
                onChange={handleDatePicker}
                onError={handleDatePickerError}
              >
                <DatePicker.Input data-qa-anchor="widget-space-datepicker-input" placeholder="Date" />
                <DatePicker.Popover zIndex={10} />
              </DatePicker>
              <div className="date-time-container">
                <TimePicker
                  data-qa-anchor="widget-space-timepicker-input"
                  defaultValue={moment().format('HH:mma')}
                  onChange={handleTimePicker}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
