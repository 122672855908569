import React from 'react';
import { useState } from 'react';
import SidePanel from '@paprika/sidepanel';
import Menu from '@acl-services/wasabicons/lib/Menu';
import useI18n from '@paprika/l10n/lib/useI18n';
import { AppsType } from 'lib/customPropTypes';

import SideNavigationItem from './SideNavigationItem';
import './SideNavigation.scss';

function SideNavigation(props: AppsType) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen((state) => !state);
  };
  const { apps } = props;
  const I18n = useI18n();

  return (
    <SidePanel className="side-navigation" isOpen={isOpen} isSlideFromLeft onClose={toggle} offsetY={40}>
      <SidePanel.Overlay />
      <SidePanel.Trigger kind="minor" onClick={toggle} className="side-navigation__trigger-button">
        <Menu />
      </SidePanel.Trigger>
      <SidePanel.Header>
        <Menu />
        <a href="/" className="side-navigation__header-title">
          <span>{I18n.t('titles.master_library')}</span>
        </a>
      </SidePanel.Header>
      {apps.map((app) => {
        return (
          <SideNavigationItem displayName={app.title} url={app.url} isActive key={app.url}>
            {React.cloneElement(app.icon, { width: '25', height: '25' })}
          </SideNavigationItem>
        );
      })}
    </SidePanel>
  );
}

export default SideNavigation;
