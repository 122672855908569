import React from 'react';
import Input from '@paprika/input';
import useToggle from '../../../../../useToggle';
import useValue from '../../../../../useValue';
import Checkbox from '@paprika/checkbox';
import useI18n from '@paprika/l10n/lib/useI18n';
import { bool } from '../../../../../types';

export interface BoolProps {
  onChange(change: bool): void;
  defaultValue: bool;
  defaultWeight: number;
}

export default function Bool(props: BoolProps) {
  const { t } = useI18n();
  const {
    onChange = () => null,
    defaultValue = {
      default: null,
      type: 'boolean',
      falseValue: t('attribute_types_details.widgets.false'),
      falseWeight: NaN,
      trueValue: t('attribute_types_details.widgets.true'),
      trueWeight: NaN,
    },
  } = props;
  const defaultValueMemo = React.useMemo(() => {
    if (defaultValue.default === null)
      return {
        defaultIsToggleTrue: null,
        defaultIsToggleFalse: null,
      };

    if (defaultValue.default === true) {
      return {
        defaultIsToggleTrue: true,
        defaultIsToggleFalse: false,
      };
    }

    return {
      defaultIsToggleTrue: false,
      defaultIsToggleFalse: true,
    };
  }, [defaultValue]);

  const [isToggleTrue, handleToggleTrue] = useToggle(
    defaultValueMemo.defaultIsToggleTrue === null ? undefined : defaultValueMemo.defaultIsToggleTrue,
  );

  const [isToggleFalse, handleToggleFalse] = useToggle(
    defaultValueMemo.defaultIsToggleFalse === null ? undefined : defaultValueMemo.defaultIsToggleTrue,
  );

  const [falseValue, handleChangeFalseValue] = useValue(defaultValue.falseValue);
  const [falseWeight, handleChangeFalseWeight] = useValue(defaultValue.falseWeight);
  const [trueValue, handleChangeTrueValue] = useValue(defaultValue.trueValue);
  const [trueWeight, handleChangeTrueWeight] = useValue(defaultValue.trueWeight);

  const handleChangeValueMiddleware = (callback: any) => (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.match(/^[0-9]*$/)) {
      callback(event.currentTarget.value);
      return;
    }
  };

  React.useEffect(() => {
    const defaultValue = () => {
      if (isToggleTrue) return true;
      if (isToggleFalse) return false;
      if (!isToggleTrue && !isToggleFalse) return null;
    };

    const response: bool = {
      default: defaultValue() as boolean | null,
      falseValue,
      falseWeight: Number.parseInt(falseWeight, 10),
      trueWeight: Number.parseInt(trueWeight, 10),
      trueValue,
      type: 'boolean',
    };

    onChange(response);
  }, [isToggleTrue, isToggleFalse, trueValue, falseValue, trueWeight, falseWeight, onChange]);

  const handleToggleTrueMiddleware = () => {
    if (isToggleTrue === true) {
      handleToggleTrue(undefined);
      return;
    }
    handleToggleTrue(true);
    handleToggleFalse(false);
  };

  const handleToggleFalseMiddleware = () => {
    if (isToggleFalse === true) {
      handleToggleFalse(undefined);
      return;
    }

    handleToggleTrue(false);
    handleToggleFalse(true);
  };

  return (
    <>
      <div className="widget-space widgets">
        <div className="widgets__left">{t('attribute_types_details.widgets.option')}</div>
        <div className="widgets__right">{t('attribute_types_details.widgets.weight')}</div>
      </div>
      <div className="widget-space widgets">
        <div className="widgets__left">
          <Input data-qa-anchor="widgets-left-input-true" value={trueValue} onChange={handleChangeTrueValue} />
        </div>
        <div className="widgets__right">
          <Input
            data-qa-anchor="widgets-right-input-true"
            value={Number.isNaN(trueWeight) ? '' : trueWeight.toString()}
            onChange={handleChangeValueMiddleware(handleChangeTrueWeight)}
          />
        </div>
      </div>
      <div className="widget-space">
        <Checkbox
          data-qa-anchor="widget-space-checkbox-true"
          checkedState={useToggle.toCheckBox(isToggleTrue)}
          onChange={handleToggleTrueMiddleware}
        >
          {t('attribute_types_details.widgets.set_default_values')}
        </Checkbox>
      </div>
      <div className="widget-space widgets">
        <div className="widgets__left">
          <Input data-qa-anchor="widgets-left-input-false" value={falseValue} onChange={handleChangeFalseValue} />
        </div>
        <div className="widgets__right">
          <Input
            data-qa-anchor="widgets-right-input-false"
            value={Number.isNaN(falseWeight) ? '' : falseWeight.toString()}
            onChange={handleChangeValueMiddleware(handleChangeFalseWeight)}
          />
        </div>
      </div>
      <div className="widget-space">
        <Checkbox
          data-qa-anchor="widget-space-checkbox-false"
          checkedState={useToggle.toCheckBox(isToggleFalse)}
          onChange={handleToggleFalseMiddleware}
        >
          {t('attribute_types_details.widgets.set_default_values')}
        </Checkbox>
      </div>
    </>
  );
}
