import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Guard from '@paprika/guard';
import PaprikaSidePanel from '@paprika/sidepanel';
import useI18n from '@paprika/l10n/lib/useI18n';
import AttributeTypeDetailsProvider, {
  ActionTypes,
  useAttributeTypeDetailsDispatch,
  useAttributeTypeDetails,
} from 'providers/AttributeTypeDetailsProvider';
import { AttributeTypeDetailsTypes, AttributeTypeDetails } from 'modules/AttributesTypes';
import FooterButtons from './components/FooterButtons';

export default function SidePanelWrapper() {
  const { attributeTypeId } = useParams<{ attributeTypeId: string | undefined }>();

  if (!attributeTypeId) {
    return null;
  }

  return (
    <Guard.Supervisor>
      <AttributeTypeDetailsProvider key={attributeTypeId}>
        <SidePanel />
      </AttributeTypeDetailsProvider>
    </Guard.Supervisor>
  );
}

function SidePanel() {
  const [isDirty, setIsDirty] = React.useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const { attributeTypeId } = useParams<{ attributeTypeId: string | undefined }>();
  const dispatch = useAttributeTypeDetailsDispatch();
  const { data, error, isSaving } = useAttributeTypeDetails();
  const I18n = useI18n();
  const isOpen = !!attributeTypeId;

  const handleChangeAttributeTypesDetails = (
    data: AttributeTypeDetailsTypes.AttributeTypeSpecific<AttributeTypeDetailsTypes.AttributeTypeDataType>,
  ) => {
    setIsDirty(true);
    dispatch({ type: ActionTypes.UPDATE_ATTRIBUTE_TYPE, payload: data });
  };

  // TODO: considering not using onChange as dependency in useEffect, need to change the widgets
  const memorized = React.useCallback(handleChangeAttributeTypesDetails, []);

  return (
    <Guard.Gatekeeper alertMessage={I18n.t('attribute_types_details.dirty_message')}>
      {(canLeave: () => boolean) => (
        <PaprikaSidePanel
          isOpen={isOpen}
          onClose={() => {
            if (canLeave()) {
              history.push(pathname.substring(0, pathname.lastIndexOf('/')));
            }
          }}
        >
          <Guard.Connector isDirty={isDirty} />
          <PaprikaSidePanel.Header>Attribute type {attributeTypeId} details</PaprikaSidePanel.Header>
          <AttributeTypeDetails attributeTypeDetails={data} error={error} isSaving={isSaving} onChange={memorized} />
          <PaprikaSidePanel.Footer>
            <FooterButtons isDirty={isDirty} isRelated setIsDirty={setIsDirty} />
          </PaprikaSidePanel.Footer>
        </PaprikaSidePanel>
      )}
    </Guard.Gatekeeper>
  );
}
