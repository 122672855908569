import React from 'react';
import Input from '@paprika/input';
import useToggle from '../../../../../useToggle';
import Checkbox from '@paprika/checkbox';
import useI18n from '@paprika/l10n/lib/useI18n';
import { num } from '../../../../../types';

export interface NumberProps {
  onChange(change: num): void;
  defaultAllowDecimals: boolean;
  defaultValue?: number;
}

export default function Paragraph({ onChange = () => null, defaultValue, defaultAllowDecimals }: NumberProps) {
  const [allowDecimals, handleAllowDecimals] = useToggle(defaultAllowDecimals);
  const [isToggle, handleToggle] = useToggle(Boolean(defaultValue));
  const [value, setValue] = React.useState<string>(defaultValue?.toString() || '');
  const { t } = useI18n();

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    setValue(event.currentTarget.value);
  }

  React.useEffect(() => {
    let numberValue = allowDecimals ? Number.parseFloat(value) : Number.parseInt(value, 10);
    if (allowDecimals && value.includes(',')) {
      const replaceValueWithDot = value.replace(',', '.');
      numberValue = Number.parseFloat(replaceValueWithDot);
    }

    const response: num = {
      default: numberValue,
      isDecimal: allowDecimals as boolean,
      type: 'number',
    };

    onChange(response);
  }, [allowDecimals, onChange, value]);

  React.useEffect(() => {
    if (allowDecimals) {
      if (value.match(/^[0-9]*[.,]?[0-9]*$/)) {
        return;
      }
      setValue((prev) => prev.slice(0, -1));
      return;
    }

    if (!value.match(/^[0-9]*[0-9]*$/)) {
      setValue((prev) => prev.slice(0, -1));
    }
  }, [allowDecimals, value]);

  return (
    <>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(allowDecimals)} onChange={handleAllowDecimals}>
          {t('attribute_types_details.widgets.allow_decimals')}
        </Checkbox>
      </div>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggle)} onChange={handleToggle}>
          {t('attribute_types_details.widgets.set_default_values')}
        </Checkbox>
      </div>

      {isToggle ? (
        <div className="widget-space">
          <Input data-qa-anchor="widgets-space-input" value={value} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
}
