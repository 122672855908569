import React from 'react';
import useI18n from '@paprika/l10n/lib/useI18n';
import moment from 'moment';

import AttributeTypeName, { AttributeTypeData } from './components/AttributeTypeName';
import ResponseType from './components/ResponseType';
import RecentActivity from './components/RecentActivity';
import { responseTypes, AttributeTypeDataType, AttributeTypeSpecific } from './types';

interface AttributeTypeDetailsProps {
  attributeTypeDetails: AttributeTypeData;
  error: string | null;
  isSaving: boolean;
  onChange(attributeType: AttributeTypeSpecific<AttributeTypeDataType>): void;
}

export default function AttributeTypeDetails(props: AttributeTypeDetailsProps) {
  const { attributeTypeDetails, error, isSaving, onChange } = props;
  const i18n = useI18n();

  function handleChangeTypeName(value: AttributeTypeData) {
    setAttributeTypeName(value);
  }

  // const [attributeTypesValue, setAttributeTypesValue] = React.useState<AttributeTypeSpecific<AttributeTypeDataType>>();
  // TODO: Passing default response type data
  const [responseTypeValue, setResponseTypeValue] = React.useState<responseTypes>();
  const [attributeTypeName, setAttributeTypeName] = React.useState<AttributeTypeData>();

  const handleChangeResponseType = React.useCallback((value: responseTypes) => {
    setResponseTypeValue(value);
  }, []);

  React.useEffect(() => {
    moment.locale(i18n.locale);
  }, [i18n.locale]);

  React.useEffect(() => {
    if (responseTypeValue?.type && attributeTypeName) {
      const type: AttributeTypeDataType = responseTypeValue.type;
      const attributeTypeDetails: AttributeTypeSpecific<typeof type> = {
        name: attributeTypeName.name as string,
        displayName: attributeTypeName.displayName as string,
        tooltip: attributeTypeName.tooltip as string,
        isGlobal: attributeTypeName.isGlobal as boolean,
        isRequired: attributeTypeName.isRequired as boolean,
        isHidden: attributeTypeName.isHidden as boolean,
        dataType: type,
        typeOptions: responseTypeValue,
      };

      onChange(attributeTypeDetails);
    }
  }, [responseTypeValue, attributeTypeName, onChange]);

  return (
    <>
      <AttributeTypeName
        attributeTypeDetails={attributeTypeDetails}
        error={error}
        isSaving={isSaving}
        onChange={handleChangeTypeName}
      />
      <hr />
      {/* TODO: pass response type options from 'data' */}
      <ResponseType onChange={handleChangeResponseType} isSaving={false} error="" />
      <hr />
      <RecentActivity
        activity={{
          createdAt: '03/09/2020',
          createdBy: 'Mark Hamill',
          editedAt: '03/09/2020',
          editedBy: 'Obi-wan Kenobi',
          url: 'http://wegalvanize.com',
        }}
      />
    </>
  );
}
