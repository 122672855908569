import React from 'react';
import _ from 'lodash';
import Button from '@paprika/button';
import FormElement from '@paprika/form-element';
import Checkbox from '@paprika/checkbox';
import Select from '@paprika/select';
import Switch from '@paprika/switch';
import AssetsApi from 'services/AssetsApi';
import serializer from 'serializers';
import { Select as AttributeSelect } from '@acl-services/sriracha-attribute-type-associator';
import '@acl-services/sriracha-attribute-type-associator/dist/AttributeTypeAssociator.scss';
import { generateAttributeType } from './AttributeType';
import AdHocRelationshipLabelCreator, { Label } from 'components/AdHocRelationshipLabelCreator';

import './AttributeTypes.scss';

const { CHECKED, UNCHECKED } = Checkbox.types.state;

const initialSelectAttributeData = {
  options: [{ name: '', weight: '', color: '' }],
  isMultiple: false,
  hasDefaultValue: false,
  defaultValue: [],
};

export default function AttributeTypes() {
  const [attributeType, setAttributeType] = React.useState<any>({
    typeOptions: {},
    isGlobal: UNCHECKED,
    isRequired: UNCHECKED,
  });
  const [attributeTypes, setAttributeTypes] = React.useState<any>();
  const [error, setError] = React.useState('');
  const [data, setData] = React.useState(initialSelectAttributeData);
  const selectRef = React.useRef(null);

  const createAttributeType = async () => {
    if (attributeType.dataType === 'select') {
      if (!selectRef.current) {
        return;
      }

      // @ts-ignore
      const hasAnyError = selectRef.current.validate();
      if (hasAnyError) {
        return;
      }
    }
    let response = await AssetsApi.fetch('/attribute_types', {
      method: 'POST',
      body: serializer.serialize('attribute_types', generateAttributeType(attributeType)),
    });
    if (response.ok) {
      setAttributeTypes([serializer.deserialize('attribute_types', await response.json())]);
    } else {
      setError(`${response.status}: ${response.statusText}`);
    }
  };

  const listAttributeTypes = async () => {
    let response = await AssetsApi.fetch('/attribute_types', {
      method: 'GET',
    });
    if (response.ok) {
      setAttributeTypes(serializer.deserialize('attribute_types', await response.json()));
    } else {
      setError(`${response.status}: ${response.statusText}`);
    }
  };

  const getDefaultTypeOptions = (dataType: string) => {
    switch (dataType) {
      case 'number':
        return {
          default: 0,
          isDecimal: false,
        };
      case 'dateTime':
        return {
          default: '',
          is24Hour: false,
        };
      case 'boolean':
        return {
          default: true,
        };
      case 'paragraph':
        return {
          default: '',
          isRichText: false,
        };
      case 'text':
        return {
          default: '',
        };
      case 'userLookup':
        return {
          default: { userIds: ['current'], group_ids: [] },
          isMultiSelect: false,
          isSelectUser: true,
          isSelectGroup: false,
          roles: [],
        };
      case 'adHocRelationship':
        return {};
      default:
        return {};
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const targetKey = event.target.name;

    if (targetKey === 'dataType') {
      const typeOptions = getDefaultTypeOptions(newValue);

      setAttributeType((prevState: any) => ({
        ...prevState,
        [targetKey]: newValue,
        typeOptions,
      }));
    } else {
      setAttributeType((prevState: any) => ({
        ...prevState,
        [targetKey]: newValue,
      }));
    }
  };

  const onOptionInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string | number = event.target.value;
    const targetKey = event.target.name;
    if (event.target.type === 'number') {
      newValue = Number(newValue);
    }

    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        [targetKey]: newValue,
      },
    }));
  };

  const onOptionSwitchChange = (targetKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldValue = attributeType.typeOptions[targetKey];
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        [targetKey]: !oldValue,
      },
    }));
  };

  const onGlobalChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setAttributeType((prevState: Record<string, unknown>) => ({
      ...prevState,
      isGlobal: attributeType.isGlobal === CHECKED ? UNCHECKED : CHECKED,
    }));
  };

  const onRequiredChange = () => {
    setAttributeType((prevState: Record<string, unknown>) => ({
      ...prevState,
      isRequired: attributeType.isRequired === CHECKED ? UNCHECKED : CHECKED,
    }));
  };

  const onOptionInputListChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue: string = event.target.value;
    const targetKey = event.target.name;
    let listOfValues = newValue.split(',').map((val) => val.trim());
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        [targetKey]: listOfValues,
      },
    }));
  };

  const onDefaultOptionInputListChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldType?: string,
  ) => {
    let newValue: string = event.target.value;
    const targetKey = event.target.name;
    let listOfValues = newValue.split(',').map((val) => val.trim());

    // User Lookup should send [] instead of [""]
    if (fieldType === 'userLookup') {
      listOfValues = _.isEqual(listOfValues, ['']) ? [] : listOfValues;
    }

    setAttributeType((prevState: any) => {
      let newDefaults = { ...prevState.typeOptions.default, [targetKey]: listOfValues };
      return {
        ...prevState,
        typeOptions: {
          ...prevState.typeOptions,
          default: newDefaults,
        },
      };
    });
  };

  const onAdHocRelationshipAttributeChange = (labels: Label[]) => {
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        ...prevState.typeOptions,
        labels,
      },
    }));
  };

  const renderDefaultValueInput = (type?: string) => (
    <FormElement label="Default">
      <FormElement.Content>
        {({ idForLabel }: { idForLabel: string }) => (
          <input
            id={idForLabel}
            name="default"
            value={attributeType.typeOptions.default}
            type={type || 'text'}
            onChange={onOptionInputChange}
          />
        )}
      </FormElement.Content>
    </FormElement>
  );

  const renderSwitchInput = (property: string) => (
    <FormElement label={property.charAt(0).toLowerCase() + property.slice(1)} isInline>
      <FormElement.Content>
        {({ idForLabel }: { idForLabel: string }) => (
          <>
            <Switch
              id={idForLabel}
              isChecked={attributeType.typeOptions[property]}
              onChange={onOptionSwitchChange(property)}
            />
            <span className="switch-description">{attributeType.typeOptions[property] ? 'True' : 'False'}</span>
          </>
        )}
      </FormElement.Content>
    </FormElement>
  );

  const onSelectAttributesChange = React.useCallback((newState) => {
    setData(newState);
    const defaultValue = JSON.stringify(newState.defaultValue[0]);
    const selectValues = newState.options.map((option: any, index: number) => {
      const sortOrder = index + 1;
      return {
        ...option,
        sortOrder,
        default: defaultValue === JSON.stringify(option),
      };
    });
    setAttributeType((prevState: any) => ({
      ...prevState,
      typeOptions: {
        selectValues,
      },
    }));
  }, []);

  const renderDataTypeOptions = (dataType: string) => {
    switch (dataType) {
      case 'boolean':
        return (
          <>
            <h3>Boolean Options</h3>
            {renderSwitchInput('default')}
          </>
        );
      case 'dateTime':
        return (
          <>
            <h3>Date Time Options</h3>
            {renderDefaultValueInput(attributeType.typeOptions.is24Hour ? 'datetime-local' : 'date')}
            {renderSwitchInput('is24Hour')}
          </>
        );
      case 'number':
        return (
          <>
            <h3>Number Options</h3>
            {renderDefaultValueInput('number')}
            {renderSwitchInput('isDecimal')}
          </>
        );
      case 'paragraph':
        return (
          <>
            <h3>Paragraph Options</h3>
            <FormElement label="Default">
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <textarea
                    id={idForLabel}
                    name="default"
                    value={attributeType.typeOptions.default}
                    onChange={onOptionInputChange}
                  />
                )}
              </FormElement.Content>
            </FormElement>
            {renderSwitchInput('isRichText')}
          </>
        );
      case 'text':
        return (
          <>
            <h5>Text Options</h5>
            {renderDefaultValueInput()}
          </>
        );
      case 'select':
        return (
          <div className="sriracha" data-qa-anchor="select-sortable">
            <AttributeSelect data={data} onChange={onSelectAttributesChange} ref={selectRef} />
          </div>
        );
      case 'userLookup':
        return (
          <>
            <h3>User Lookup Options</h3>
            <FormElement label="Default User IDs (Comma Delimited)">
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <textarea
                    id={idForLabel}
                    name="userIds"
                    value={attributeType.typeOptions.default.userIds}
                    onChange={(event) => onDefaultOptionInputListChange(event, 'userLookup')}
                  />
                )}
              </FormElement.Content>
            </FormElement>
            <FormElement label="Role IDs (Comma Delimited)">
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <textarea
                    id={idForLabel}
                    name="roles"
                    value={attributeType.typeOptions.roles}
                    onChange={onOptionInputListChange}
                  />
                )}
              </FormElement.Content>
            </FormElement>
            {renderSwitchInput('isMultiSelect')}
            {renderSwitchInput('isSelectUser')}
            {renderSwitchInput('isSelectGroup')}
          </>
        );
      case 'adHocRelationship':
        return (
          <>
            <h3>Ad Hoc Relationship Options</h3>
            <p>!! In Development !!</p>
            <hr />
            <AdHocRelationshipLabelCreator
              onChange={onAdHocRelationshipAttributeChange}
            ></AdHocRelationshipLabelCreator>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderAttributeTypeInputField = (fieldName: string) => (
    <FormElement key={fieldName} label={fieldName} isInline>
      <FormElement.Content>
        {({ idForLabel }: { idForLabel: string }) => (
          <input
            id={idForLabel}
            name={fieldName.charAt(0).toLowerCase() + fieldName.slice(1)}
            onChange={onInputChange}
            data-qa-anchor={`attribute-type-${fieldName.charAt(0).toLowerCase() + fieldName.slice(1)}-input`}
          />
        )}
      </FormElement.Content>
    </FormElement>
  );

  return (
    <div className="horrendous-ui-attribute-types">
      <h2>Attribute Types</h2>
      <p>Welcome to Attribute type creation</p>
      {['DisplayName', 'Name', 'Tooltip'].map((field) => renderAttributeTypeInputField(field))}
      <Checkbox
        name="isGlobal"
        onChange={onGlobalChange}
        checkedState={attributeType.isGlobal}
        data-qa-anchor="attribute-type-is-global-checkbox"
      >
        Global
      </Checkbox>
      <Checkbox
        onChange={onRequiredChange}
        checkedState={attributeType.isRequired}
        data-qa-anchor="attribute-type-is-required-checkbox"
      >
        Required
      </Checkbox>
      <FormElement label="Data type">
        <FormElement.Content>
          <Select
            placeholder="Select an option"
            style={{ width: '10%' }}
            name="dataType"
            onChange={onInputChange}
            value={attributeType.dataType}
            data-qa-anchor="attribute-type-data-type-select"
          >
            <option value="boolean">Boolean</option>
            <option value="dateTime">Date Time</option>
            <option value="number">Number</option>
            <option value="paragraph">Paragraph</option>
            <option value="text">Text</option>
            <option value="select">Select</option>
            <option value="userLookup">User Lookup</option>
            <option value="adHocRelationship">Ad Hoc Relationship</option>
          </Select>
        </FormElement.Content>
      </FormElement>
      {attributeType.dataType && (
        <div className="type-options-panel">{renderDataTypeOptions(attributeType.dataType)}</div>
      )}
      <div>
        <Button kind="primary" onClick={createAttributeType}>
          Create Attribute Type
        </Button>
        <Button kind="primary" onClick={listAttributeTypes}>
          List Attribute Types
        </Button>
      </div>
      {attributeTypes ? (
        <table>
          <caption>
            <h2>Attribute Types List</h2>
          </caption>
          <thead>
            <tr>
              <th>Field ID</th>
              <th>Global</th>
              <th>Field Name</th>
              <th>Display Name</th>
              <th>Data Type</th>
              <th>Type Options</th>
              <th>Required</th>
              <th>Tooltip</th>
              <th>Created By</th>
              <th>Updated By</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {attributeTypes.map(
              ({
                id,
                fieldName,
                displayName,
                dataType,
                typeOptions,
                isGlobal,
                isRequired,
                tooltip,
                updatedBy,
                updatedAt,
                createdBy,
                createdAt,
              }: any) => (
                <tr key={fieldName}>
                  <td>{id}</td>
                  <td>{isGlobal.toString()}</td>
                  <td>{fieldName}</td>
                  <td>{displayName}</td>
                  <td>{dataType}</td>
                  <td>{JSON.stringify(typeOptions)}</td>
                  <td>{isRequired.toString()}</td>
                  <td>{tooltip}</td>
                  <td>
                    {createdBy}
                    <br />
                    <small>{createdAt}</small>
                  </td>
                  <td>
                    {updatedBy}
                    <br />
                    <small>{updatedAt}</small>
                  </td>
                  <td>
                    <Button.Link href={`horrendous-ui/attribute_types/${id}`} isOpenNewTab={false}>
                      View Details
                    </Button.Link>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : error ? (
        error
      ) : null}
    </div>
  );
}
