import React from 'react';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '@paprika/button';
import Card from '@paprika/card';
import Heading from '@paprika/heading';
import useI18n from '@paprika/l10n/lib/useI18n';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import Spinner from '@paprika/spinner';
import { ActionTypes, useAppState, useAppStateDispatch } from 'providers/AppStateProvider';
import AssetsApi from 'services/AssetsApi';
import AssetTypeInterface from 'interfaces/AssetType';
import serializer from 'serializers';

import Avatar from './components/Avatar';
import './AssetTypes.scss';

interface AssetTypesProps {
  assetTypes: AssetTypeInterface[];
  locale: string;
}

export function AssetTypes({ assetTypes, locale }: AssetTypesProps) {
  const { t } = useI18n();
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="asset-types">
      <Heading level={1} isLight>
        {t('titles.asset_types')}
        <Button
          onClick={() => {
            history.push(`${location.pathname}/new`);
          }}
          size={ShirtSizes.LARGE}
        >
          + {t('actions.add')}
        </Button>
      </Heading>
      {Object.entries(assetTypes).map(([id, assetType]: any) => {
        const name = assetType.isArchived ? t('asset_type.archived_name', { name: assetType.name }) : assetType.name;

        return (
          <Link
            className="asset-types__card-link"
            to={`${location.pathname}/${id}`}
            key={`card-${id}`}
            data-qa-anchor={`asset-types-link-${id}`}
          >
            <Card>
              <Card.Header>
                <Avatar isArchived={assetType.isArchived} name={assetType.name} />
              </Card.Header>
              <Card.Content>
                <Card.Title>{name}</Card.Title>
                <Card.Metadata>&nbsp;{/*t('counters.total', { count: assetType.assetsCount })*/}</Card.Metadata>
                <Card.Text>{assetType.description}</Card.Text>
                <Card.Metadata>{t('asset_type.created_by', { name: assetType.createdBy })}</Card.Metadata>
                <Card.Metadata>
                  {t('asset_type.updated_by', {
                    when: moment(assetType.updatedAt).locale(locale).fromNow(),
                    name: assetType.updatedBy,
                  })}
                </Card.Metadata>
              </Card.Content>
              <Card.Footer className="asset-types__card-footer">
                <span>
                  <strong>{assetType.attributeTypesCount}</strong> {t('counters.attributes')}
                </span>
                {/* <span>
                  <strong>123</strong> {t('counters.questions')}
                </span> */}
              </Card.Footer>
            </Card>
          </Link>
        );
      })}
    </div>
  );
}

export default function AssetTypesWrapper() {
  const { locale } = useI18n();
  const { assetTypes, hasAssetTypes } = useAppState();
  const dispatch = useAppStateDispatch();

  React.useEffect(() => {
    async function fetchAssetTypes() {
      const response = await AssetsApi.fetch('/asset_types');
      const assetTypesJson = await response.json();
      dispatch({ type: ActionTypes.SET_ASSET_TYPES, payload: serializer.deserialize('asset_types', assetTypesJson) });
    }

    fetchAssetTypes();
  }, [dispatch]);

  if (!locale || !hasAssetTypes) {
    return <Spinner size={ShirtSizes.MEDIUM} />;
  }

  return <AssetTypes assetTypes={assetTypes} locale={locale} />;
}
