import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';
import AclEssentialsLogo from '@acl-services/wasabicons/lib/AclEssentialsLogo';
import Placeholder from '@acl-services/wasabicons/lib/Placeholder';
import L10n from '@paprika/l10n';
import SessionProvider, { SessionContext } from 'providers/SessionProvider';
import AppStateProvider from 'providers/AppStateProvider';
import { OverlayContext } from 'providers/OverlayContextProvider';
import ErrorPageContextProvider from 'providers/ErrorPageProvider';
import GlobalNav from 'components/GlobalNav/GlobalNav';
import SideBar from 'components/SideBar';
import Dashboard from 'pages/Dashboard';
import Locales from 'locales';
import getLocale from 'lib/helpers/getLocales';
import AssetTypePage from 'pages/AssetType';
import AssetTypesPage from 'pages/AssetTypes';
import AssetTypeDetailsPage from 'pages/HorrendousUI/AssetType';
import AttributeTypeDetailsPage from 'pages/HorrendousUI/AttributeType';
import HorrendousUIPage from 'pages/HorrendousUI';
import ErrorPage from 'pages/Error/ErrorPage';
import './App.scss';

const availableApps = [
  {
    key: 'asset_types',
    title: 'Asset types',
    url: '/asset_types',
    icon: <AclEssentialsLogo data-qa-anchor="app-icon" />,
    count: 5,
  },
  {
    key: 'horrendous_ui',
    title: 'Horrendous UI',
    url: '/horrendous-ui',
    icon: <Placeholder />,
    count: 5,
  },
];

function App() {
  const overlayContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <AppStateProvider>
      <SessionProvider>
        <OverlayContext.Provider value={overlayContainerRef}>
          <SessionContext.Consumer>
            {(session) => {
              const sessionLocale = getLocale(session && session.locale);
              return (
                <L10n locale={sessionLocale} locales={Locales}>
                  <div className="app">
                    <ErrorPageContextProvider>
                      <GlobalNav />
                      <div className="master-library__container">
                        <SideBar apps={availableApps} />
                        <div className="app-content" ref={overlayContainerRef}>
                          <Router>
                            <Switch>
                              <Route exact path="/">
                                <Dashboard apps={availableApps} />
                              </Route>
                              <Route exact path="/asset_types">
                                <AssetTypesPage />
                              </Route>
                              <Route path="/asset_types/:assetTypeId">
                                <AssetTypePage />
                              </Route>
                              {/* TODO Horrendous UI pages to remove */}
                              <Route exact path="/horrendous-ui">
                                <HorrendousUIPage />
                              </Route>
                              <Route exact path="/horrendous-ui/asset_types/:assetTypeId">
                                <AssetTypeDetailsPage />
                              </Route>
                              <Route exact path="/horrendous-ui/attribute_types/:attributeTypeId">
                                <AttributeTypeDetailsPage />
                              </Route>
                              <Route exact path="/403">
                                <ErrorPage headingKey="error.access_not_allowed" bodyTextKey="error.no_permission" />
                              </Route>
                              <Route>
                                <ErrorPage
                                  headingKey="error.page_not_found"
                                  bodyTextKey="error.page_couldnt_be_found"
                                />
                              </Route>
                            </Switch>
                          </Router>
                        </div>
                      </div>
                    </ErrorPageContextProvider>
                  </div>
                </L10n>
              );
            }}
          </SessionContext.Consumer>
        </OverlayContext.Provider>
      </SessionProvider>
    </AppStateProvider>
  );
}

export default App;
