import React from 'react';
import Textarea from '@paprika/textarea';
import useToggle from '../../../../../useToggle';
import Checkbox from '@paprika/checkbox';
import useI18n from '@paprika/l10n/lib/useI18n';
import { paragraph } from '../../../../../types';

export interface ParagraphProps {
  onChange(type: paragraph): void;
  defaultAllowRichTextEditor: boolean;
  defaultValue: string;
}

export default function Paragraph({ onChange, defaultValue, defaultAllowRichTextEditor }: ParagraphProps) {
  const [allowRichText, handleAllowRichText] = useToggle(defaultAllowRichTextEditor);
  const [isToggle, handleToggle] = useToggle(Boolean(defaultValue));
  const [value, setValue] = React.useState(defaultValue);
  const { t } = useI18n();

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    setValue(event.currentTarget.value);
  }

  React.useEffect(() => {
    const response: paragraph = {
      default: value,
      isRichText: Boolean(allowRichText),
      type: 'paragraph',
    };

    onChange(response);
  }, [allowRichText, onChange, value]);

  React.useEffect(() => {
    if (!isToggle) {
      const response: paragraph = {
        default: undefined,
        isRichText: Boolean(allowRichText),
        type: 'paragraph',
      };

      onChange(response);
    }
  }, [allowRichText, isToggle, onChange]);

  return (
    <>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(allowRichText)} onChange={handleAllowRichText}>
          {t('attribute_types_details.widgets.allow_rich_text')}
        </Checkbox>
      </div>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggle)} onChange={handleToggle}>
          {t('attribute_types_details.widgets.set_default_values')}
        </Checkbox>
      </div>
      {isToggle ? (
        <div className="widget-space">
          <Textarea data-qa-anchor="widgets-space-textarea" value={value} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
}
