import React from 'react';
import GlobalNavigation, {
  AppSwitcherSidePanel,
  CurrentApp,
  Helper,
  Logo,
  User,
} from '@acl-services/sriracha-global-navigation';
import { useSession } from 'providers/SessionProvider';
import LaunchpadApiProvider, { useLaunchpadApi } from 'providers/LaunchpadApiProvider';
import { useErrorPage } from 'providers/ErrorPageProvider';

const helpDocUrl = 'https://www.wegalvanize.com'; // TODO: replace when helpdocs are available

export function GlobalNav() {
  const session = useSession();
  const [navData, setNavData] = React.useState<any>();
  const launchpadApi = useLaunchpadApi();
  const { isErrorPage } = useErrorPage();

  React.useEffect(() => {
    async function fetchGlobalNav() {
      if (session?.org_id) {
        const navBarJson = await launchpadApi.getGlobalNavBar(session.org_id);
        setNavData(navBarJson.data);
      }
    }

    fetchGlobalNav();
  }, [session, launchpadApi]);

  if (isErrorPage) return null;

  return (
    <div className="sriracha">
      {navData !== undefined ? (
        <GlobalNavigation mainContentSelector="#main">
          <Logo launchpadUrl={navData.links.launchpad} />
          <CurrentApp appId="master_library" appUrl={window.location.origin} />
          <Helper helpDocUrl={helpDocUrl} supportUrl={navData.links.support} />
          <User
            customerName={navData.customerName}
            username={navData.userFullName}
            userProfileUrl={navData.links.profile}
            logoutUrl={navData.links.logout}
          />

          <AppSwitcherSidePanel {...navData.appSwitcherProps} />
        </GlobalNavigation>
      ) : (
        <GlobalNavigation mainContentSelector="#main" />
      )}
    </div>
  );
}

export default function GlobalNavWithProvider() {
  return (
    <LaunchpadApiProvider>
      <GlobalNav />
    </LaunchpadApiProvider>
  );
}
