import React from 'react';
import useI18n from '@paprika/l10n/lib/useI18n';
import { Link } from 'react-router-dom';

import './LinkCard.scss';

type Props = {
  title: string;
  count: number;
  url: string;
  icon: React.ReactNode;
};

function LinkCard(props: Props) {
  const I18n = useI18n();
  const { icon, url, title, count } = props;

  return (
    <Link to={url} className="dashboard-link-card" href={url} data-qa-anchor="dashboard-link-card">
      <div className="dashboard-link-card__icon" data-qa-anchor="link-card-icon">
        {icon}
      </div>
      <div className="dashboard-link-card__title" data-qa-anchor="link-card-title">
        {title}
      </div>
      <div className="dashboard-link-card__count" data-qa-anchor="link-card-count">
        {I18n.t('counters.total', { count: count })}
      </div>
    </Link>
  );
}

export default LinkCard;
