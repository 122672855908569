import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@paprika/button';
import ArrowLeft from '@paprika/icon/lib/ArrowLeft';
import useI18n from '@paprika/l10n/lib/useI18n';
import FormElement from '@paprika/form-element';
import Toast from '@paprika/toast';
import AssetsApi from 'services/AssetsApi';
import serializer from 'serializers';
import { useWorkflows, workflowName, Workflow, getWorkflow } from './AssetTypes';

import './AssetType.scss';

function AssetType() {
  const { assetTypeId } = useParams<{ assetTypeId: string }>();
  const [assetType, setAssetType] = React.useState<any>();
  const [attributeTypes, setAttributeTypes] = React.useState([]);
  const [attributeTypeId, setAttributeTypeId] = React.useState<any>();
  const [error, setError] = React.useState('');
  const [retryMessage, setRetryMessage] = React.useState('');
  const I18n = useI18n();
  const workflows = useWorkflows(setError);

  const getAssetTypeAttributeTypes = React.useCallback(async (assetTypeId: string) => {
    const result = await AssetsApi.fetch(`/asset_types/${assetTypeId}/attribute_types`);
    if (result.ok) {
      return serializer.deserialize('attribute_types', await result.json());
    } else {
      setError(`${result.status}: ${result.statusText}`);
    }
  }, []);

  React.useEffect(() => {
    (async function () {
      let response = await AssetsApi.fetch('/attribute_types', {
        method: 'GET',
      });
      if (response.ok) {
        let attributeTypes = serializer.deserialize('attribute_types', await response.json());
        setAttributeTypes(attributeTypes);
        if (attributeTypes.length > 0) {
          setAttributeTypeId(attributeTypes.filter((attributeType: any) => attributeType.isGlobal)[0].id);
        }
      } else {
        setError(`${response.status}: ${response.statusText}`);
      }
    })();
  }, [assetTypeId]);

  React.useEffect(() => {
    (async function () {
      const result = await AssetsApi.fetch(
        `/asset_types/${assetTypeId}?filter[isArchived]=true&include=attribute_types`,
      );
      if (result.ok) {
        const assetType = serializer.deserialize('asset_types', await result.json());
        setAssetType({ ...assetType });
      } else {
        setError(`${result.status}: ${result.statusText}`);
      }
    })();
  }, [assetTypeId]);

  const unlinkAttributeTypeFromAssetType = async (attributeTypeId: string) => {
    const result = await AssetsApi.fetch(`/asset_types/${assetTypeId}/attribute_types/${attributeTypeId}`, {
      method: 'DELETE',
      body: {},
    });
    if (result.ok) {
      setAssetType(serializer.deserialize('asset_types', await result.json()));
    } else {
      setError(`${result.status}: ${result.statusText}`);
    }
  };

  const renderAttributeType = (attributeType: any) => {
    const {
      id,
      fieldName,
      displayName,
      dataType,
      typeOptions,
      isGlobal,
      isMetadata,
      isRequired,
      tooltip,
      updatedBy,
      updatedAt,
      createdBy,
      createdAt,
    } = attributeType;

    return (
      <tr key={fieldName}>
        <td>{id}</td>
        <td>{isGlobal.toString()}</td>
        <td>{fieldName}</td>
        <td>{displayName}</td>
        <td>{dataType}</td>
        <td>{JSON.stringify(typeOptions, null, ' ')}</td>
        <td>{isRequired.toString()}</td>
        <td>{tooltip}</td>
        <td>
          {createdBy}
          <br />
          <small>{createdAt}</small>
        </td>
        <td>
          {updatedBy}
          <br />
          <small>{updatedAt}</small>
        </td>
        <td>
          {!isMetadata && (
            <Button
              kind="destructive"
              onClick={() => {
                unlinkAttributeTypeFromAssetType(id);
              }}
            >
              Unlink
            </Button>
          )}
        </td>
      </tr>
    );
  };

  const onLinkAttributeTypeIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setAttributeTypeId(Number(newValue));
  };

  const addAttributeType = async (attributeTypeId: number) => {
    const result = await AssetsApi.fetch(`/asset_types/${assetTypeId}/attribute_types/${attributeTypeId}`, {
      method: 'POST',
      body: {},
    });
    if (result.ok) {
      setAssetType(serializer.deserialize('asset_types', await result.json()));
    } else {
      setError(`${result.status}: ${result.statusText}`);
    }
  };

  const updateAssetTypeDetails = async () => {
    const result = await AssetsApi.fetch(`/asset_types/${assetTypeId}`, {
      method: 'PATCH',
      body: serializer.serialize('asset_types', assetType, 'shallow'),
    });
    if (result.ok) {
      const assetType = serializer.deserialize('asset_types', await result.json());
      const attributeTypes = await getAssetTypeAttributeTypes(assetType.id);
      setAssetType({ ...assetType, attributeTypes });
    } else if (result.status === 503) {
      setRetryMessage(I18n.t('error.asset_type_update_failed'));
    } else {
      setError(`${result.status}: ${result.statusText}`);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const targetKey = event.target.name;
    setAssetType((prevState: any) => ({
      ...prevState,
      [targetKey]: newValue,
    }));
  };

  const onWorkflowChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setAssetType((prevState: Record<string, unknown>) => ({
      ...prevState,
      workflowId: newValue,
    }));
  };

  const renderWorkflowOption = (assetType: any, workflow: Workflow) => {
    const selected = assetType && assetType.workflowId === workflow.id;
    return (
      <option value={workflow.id} selected={selected}>
        {workflow.attributes.name}
      </option>
    );
  };

  return (
    <div className="horrendous-ui-wrapper">
      {retryMessage && (
        <Toast
          isFixed={true}
          kind={Toast.types.kind.ERROR}
          isOpen={true}
          onClose={() => {
            setRetryMessage('');
          }}
        >
          {retryMessage}
        </Toast>
      )}
      {assetType && !error ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Button.Link href="/horrendous-ui" isOpenNewTab={false}>
            <ArrowLeft />
            {I18n.t('actions.back')}
          </Button.Link>
          <br />
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Symbolic Name</th>
                <th>{I18n.t('asset_type.name')}</th>
                <th>Display Name</th>
                <th>Description</th>
                <th>Workflow Id</th>
                <th>Workflow Name</th>
                <th>Created By</th>
                <th>Last Updated By</th>
                <th>Is Archived</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assetType.id}</td>
                <td>{assetType.symbolicName}</td>
                <td>{assetType.name}</td>
                <td>{assetType.displayName}</td>
                <td>{assetType.description}</td>
                <td>{assetType.workflowId}</td>
                <td>{workflowName(workflows, assetType.workflowId)}</td>
                <td>
                  {assetType.createdBy}
                  <br />
                  <small>{assetType.createdAt}</small>
                </td>
                <td>
                  {assetType.updatedBy}
                  <br />
                  <small>{assetType.updatedAt}</small>
                </td>
                <td>{assetType.isArchived.toString()}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h3>Attribute Types for: {assetType.name}</h3>
          <table>
            <thead>
              <tr>
                <th>Field ID</th>
                <th>Global</th>
                <th>Field Name</th>
                <th>Display Name</th>
                <th>Data Type</th>
                <th>Type Options</th>
                <th>Required</th>
                <th>Tooltip</th>
                <th>Created</th>
                <th>Updated</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {assetType.attributeTypes &&
                assetType.attributeTypes.map((attributeType: any) => renderAttributeType(attributeType))}
            </tbody>
          </table>

          <br />
          <div>
            <h3>Actions:</h3>
            <p>Type your changes to see a preview, press update to save your changes</p>
            {['Name', 'DisplayName', 'Description'].map((key: string) => (
              <FormElement label={key} key={key} isInline>
                <FormElement.Content>
                  {({ idForLabel }: { idForLabel: string }) => (
                    <input id={idForLabel} name={key.charAt(0).toLowerCase() + key.slice(1)} onChange={onInputChange} />
                  )}
                </FormElement.Content>
              </FormElement>
            ))}
            <FormElement label="Workflow" isInline>
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <select id={idForLabel} onChange={onWorkflowChange}>
                    {workflows && workflows.map((workflow: any) => renderWorkflowOption(assetType, workflow))}
                  </select>
                )}
              </FormElement.Content>
            </FormElement>
            <Button kind="primary" onClick={updateAssetTypeDetails}>
              Update
            </Button>
            <hr />
            <FormElement label="Link Attribute Type ID" isInline>
              <FormElement.Content>
                {({ idForLabel }: { idForLabel: string }) => (
                  <select id={idForLabel} onChange={onLinkAttributeTypeIdChange} value={attributeTypeId}>
                    {Array.isArray(attributeTypes) &&
                      attributeTypes
                        .filter((attributeType: any) => attributeType.isGlobal)
                        .map((attributeType: any) => (
                          <option key={attributeType.id} value={attributeType.id}>
                            {attributeType.name}
                          </option>
                        ))}
                  </select>
                )}
              </FormElement.Content>
              <FormElement.Help>Showing only global attribute types</FormElement.Help>
            </FormElement>
            <Button kind="primary" onClick={() => addAttributeType(attributeTypeId)}>
              Link
            </Button>
            <br />
            <br />
          </div>
          <br />
          <br />
          <div className="json-view">
            <strong>Asset Type as JSON Object:</strong>
            <br />
            {JSON.stringify(
              {
                ...assetType,
                ...{
                  workflow: getWorkflow(workflows, assetType.workflowId),
                },
              },
              null,
              ' ',
            )}
          </div>
        </div>
      ) : error ? (
        error
      ) : (
        `Loading Asset Type ${assetTypeId}...`
      )}
    </div>
  );
}

export default AssetType;
