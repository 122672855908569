import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@paprika/button';
import Card from '@paprika/card';
import Confirmation from '@paprika/confirmation';
import FormElement from '@paprika/form-element';
import Textarea from '@paprika/textarea';
import Toast from '@paprika/toast';
import useI18n from '@paprika/l10n/lib/useI18n';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import StyledFormElement from 'components/StyledFormElement';
import CheckboxWithInput from 'components/CheckboxWithInput';
import FormElementContentProps from 'interfaces/FormElementContentProps';
import { useAppState } from 'providers/AppStateProvider';

import FooterCopy from './components/FooterCopy';
import './OverviewPanel.scss';

// TODO: fix the link to the Activity Log in the FooterCopy

interface OverviewPanelWrapperProps {
  id: string;
  defaultAssetTypeName: string;
  defaultDisplayName: string;
  defaultDescription: string;
  isNew: boolean;
  locale: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
}
interface OverviewPanelProps extends OverviewPanelWrapperProps {
  onClickSave: any;
  onClickArchive: any;
  serverError: string;
}

export function OverviewPanel({
  id,
  defaultAssetTypeName,
  defaultDisplayName,
  defaultDescription,
  isNew,
  locale,
  onClickSave,
  onClickArchive,
  createdBy,
  updatedBy,
  updatedAt,
  serverError,
}: OverviewPanelProps) {
  const { t } = useI18n();

  const [assetTypeName, setAssetTypeName] = React.useState(defaultAssetTypeName);
  const [displayName, setDisplayName] = React.useState(defaultDisplayName);
  const [showDisplayName, setShowDisplayName] = React.useState(defaultDisplayName.length > 0);
  const [description, setDescription] = React.useState(defaultDescription);
  const [isSaving, setIsSaving] = React.useState(false);

  const [assetTypeNameError, setAssetTypeNameError] = React.useState('');
  const [displayNameError, setDisplayNameError] = React.useState('');

  async function handleClickSave() {
    if (assetTypeName) {
      setAssetTypeNameError('');
    } else {
      setAssetTypeNameError(t('asset_type.errors.no_name'));
    }

    if (showDisplayName) {
      if (displayName) {
        setDisplayNameError('');
      } else {
        setDisplayNameError(t('asset_type.errors.no_display_name'));
      }
    }

    if (assetTypeName && (!showDisplayName || displayName)) {
      setIsSaving(true);
      await onClickSave(assetTypeName, displayName, description);
      setIsSaving(false);
    }
  }

  function handleClickCancel() {
    setAssetTypeName(defaultAssetTypeName);
    setShowDisplayName(defaultDisplayName.length > 0);
    setDisplayName(defaultDisplayName);
    setDescription(defaultDescription);

    setAssetTypeNameError('');
    setDisplayNameError('');
  }

  const primaryActionText = isNew ? t('actions.create') : t('actions.save');

  return (
    <Card isAutoHeight>
      <Card.Content>
        <StyledFormElement
          label={t('asset_type.labels.name')}
          onChange={setAssetTypeName}
          value={assetTypeName}
          placeholder={t('asset_type.placeholders.name')}
          isSaving={isSaving}
          error={assetTypeNameError}
          dataTestId="overview-panel-name-input"
        />

        <div className="overview-panel__use-display-name">
          <CheckboxWithInput
            text={displayName}
            onChange={setDisplayName}
            isExpanded={showDisplayName}
            onToggleIsExpanded={() => {
              setShowDisplayName((oldState: boolean) => !oldState);
            }}
            error={displayNameError}
            isSaving={isSaving}
            inputLabel={t('asset_type.placeholders.display_name')}
            placeholder={t('asset_type.placeholders.display_name')}
            checkboxLabel={t('asset_type.use_display_name')}
            helpText={t('asset_type.display_name_help_text')}
            dataTestId="overview-panel-display-name-input"
          />
        </div>
        <FormElement
          className="overview-panel__description"
          label={t('asset_type.labels.description')}
          size={ShirtSizes.LARGE}
          hasOptionalLabel
        >
          <FormElement.Content>
            {({ idForLabel }: FormElementContentProps) => (
              <Textarea
                id={idForLabel}
                canExpand={false}
                maxHeight="160px"
                isDisabled={isSaving}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDescription(e.target.value);
                }}
                size={ShirtSizes.LARGE}
                value={description}
                data-qa-anchor="overview-panel-description"
              ></Textarea>
            )}
          </FormElement.Content>
        </FormElement>
        {serverError && (
          <Toast kind="error" hasCloseButton={false}>
            {serverError}
          </Toast>
        )}
        <div className="overview-panel__buttons">
          <Button
            size={ShirtSizes.SMALL}
            kind={Button.Kinds.PRIMARY}
            isPending={isSaving}
            isDisabled={isSaving}
            onClick={handleClickSave}
          >
            {primaryActionText}
          </Button>
          <Button
            size={ShirtSizes.SMALL}
            kind={Button.Kinds.MINOR}
            isDisabled={isSaving}
            onClick={handleClickCancel}
            data-qa-anchor="overview-panel__cancel"
          >
            {t('actions.cancel')}
          </Button>

          <Confirmation
            align="top"
            body={t('asset_type.archive_confirmation.body')}
            buttonSize={ShirtSizes.SMALL}
            confirmButtonType={Button.Kinds.DESTRUCTIVE}
            confirmLabel={t('actions.confirm')}
            heading={t('asset_type.archive_confirmation.heading')}
            isPending={isSaving}
            onConfirm={onClickArchive}
          >
            <Confirmation.TriggerButton
              size={ShirtSizes.SMALL}
              kind={Button.Kinds.MINOR}
              isDisabled={isSaving || isNew}
            >
              {t('actions.archive')}
            </Confirmation.TriggerButton>
          </Confirmation>
        </div>

        {!isNew && <FooterCopy createdBy={createdBy} updatedBy={updatedBy} updatedAt={updatedAt} locale={locale} />}
      </Card.Content>
    </Card>
  );
}

export default function OverviewPanelWrapper({
  id,
  defaultAssetTypeName,
  defaultDisplayName,
  defaultDescription,
  isNew,
  locale,
  createdBy,
  updatedBy,
  updatedAt,
}: OverviewPanelWrapperProps) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { saveAssetType, archiveAssetType } = useAppState();
  const [serverError, setServerError] = React.useState('');

  async function handleClickSave(name: string, displayName: string, description: string) {
    const response = await saveAssetType(id, name, displayName, description);

    if (response.success && isNew) {
      history.replace(`${response.id}`);
    }

    if (!response.success) {
      setServerError(response.error);
    }
  }

  async function handleClickArchive() {
    const response = await archiveAssetType(id);

    if (response.success) {
      history.push(`${pathname}`);
    }

    if (!response.success) {
      setServerError(response.error);
    }
  }

  return (
    <OverviewPanel
      isNew={isNew}
      id={id}
      defaultAssetTypeName={defaultAssetTypeName}
      defaultDisplayName={defaultDisplayName}
      defaultDescription={defaultDescription}
      locale={locale}
      createdBy={createdBy}
      updatedBy={updatedBy}
      updatedAt={updatedAt}
      onClickSave={handleClickSave}
      onClickArchive={handleClickArchive}
      serverError={serverError}
    />
  );
}
