import React from 'react';
import Checkbox from '@paprika/checkbox';

export default function useToggle(defaultIsToggle = false) {
  const [isToggle, setIsToggle] = React.useState<boolean>(defaultIsToggle);

  React.useEffect(() => {
    setIsToggle(defaultIsToggle);
  }, [defaultIsToggle]);

  function handleToggle(value: any) {
    if (typeof value === 'undefined' || typeof value.type !== 'undefined') {
      setIsToggle((prev) => !prev);
      return;
    }

    setIsToggle(value);
  }

  return [isToggle, handleToggle] as const;
}

useToggle.toCheckBox = (value: boolean | null | (() => void)) => {
  return value ? Checkbox.types.state.CHECKED : Checkbox.types.state.UNCHECKED;
};
