import React from 'react';
import AssetsApi from 'services/AssetsApi';
import { AppConfig } from 'config';
import { SubdomainSession } from '@acl-services/jwt-highbond-client';

export const SessionContext = React.createContext({} as SubdomainSession);

export function useSession() {
  return React.useContext(SessionContext);
}

export default function SessionProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = React.useState<SubdomainSession>();
  React.useEffect(() => {
    (async function fetchSession() {
      const response = await AssetsApi.fetch('/session');
      if (response.ok) {
        setSession(await response.json());
      } else if (response.status === 401) {
        window.location.href = `${AppConfig.loginUrl}?redirect_uri=${window.location.href}`;
      } else {
        // TODO:
      }
    })();
  }, []);

  if (session) {
    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
  } else {
    return null;
  }
}
