import React from 'react';
import produce, { Draft } from 'immer';
import { useAppState } from './AppStateProvider';
import { useParams } from 'react-router-dom';
import AttributeType from '../interfaces/AttributeType';

export const AttributeTypeDetailsContext = React.createContext<any>(null);
export const AttributeTypeDetailsDispatchContext = React.createContext<any>(null);

export enum ActionTypes {
  UPDATE_ATTRIBUTE_TYPE,
  UPDATE_SAVING_STATUS,
  UPDATE_ERROR,
  CANCEL,
}

export enum AttributeTypeDetailsFields {
  name = 'name',
  displayName = 'displayName',
  tooltip = 'tooltip',
  isGlobal = 'isGlobal',
  isRequired = 'isRequired',
  isHidden = 'isHidden',
}

type Data = Record<keyof AttributeTypeDetailsFields, any>;

interface State {
  readonly initialData: Data;
  readonly data: Data;
  readonly isSaving: boolean;
  readonly error: string | null;
}

interface UpdateAttributeTypeAction {
  type: ActionTypes.UPDATE_ATTRIBUTE_TYPE;
  payload: any;
}

interface UpdateSavingStatusAction {
  type: ActionTypes.UPDATE_ATTRIBUTE_TYPE;
  payload: any;
}

interface UpdateErrorAction {
  type: ActionTypes.UPDATE_ERROR;
  payload: any;
}

interface CancelAction {
  type: ActionTypes.CANCEL;
  payload: any;
}

type Action = UpdateAttributeTypeAction | UpdateSavingStatusAction | UpdateErrorAction | CancelAction;

const reducer = (state: State, action: Action): any => {
  return produce<State>(state, (draft: Draft<State>): void => {
    switch (action.type as number) {
      case ActionTypes.UPDATE_ATTRIBUTE_TYPE:
        draft.data = Object.assign({}, action.payload);
        break;
      case ActionTypes.UPDATE_SAVING_STATUS:
        draft.error = null;
        draft.isSaving = action.payload;
        break;
      case ActionTypes.UPDATE_ERROR:
        draft.error = action.payload;
        break;
      case ActionTypes.CANCEL:
        draft.data = Object.assign({}, state.initialData);
        draft.error = null;
        draft.isSaving = false;
    }
  });
};

export default function AttributeTypeDetailsProvider({ children }: { children: React.ReactNode }) {
  const { assetTypes } = useAppState();
  const { assetTypeId, attributeTypeId } = useParams<{ assetTypeId: string; attributeTypeId: string }>();
  const initialData = React.useMemo(
    () => assetTypes[assetTypeId].attributeTypes.find((attribute: AttributeType) => attribute.id === attributeTypeId),
    [assetTypeId, assetTypes, attributeTypeId],
  );
  const [state, dispatch] = React.useReducer(reducer, {
    initialData,
    data: { ...initialData },
    isSaving: false,
    error: null,
  });

  return (
    <AttributeTypeDetailsDispatchContext.Provider value={dispatch}>
      <AttributeTypeDetailsContext.Provider value={state}>{children}</AttributeTypeDetailsContext.Provider>
    </AttributeTypeDetailsDispatchContext.Provider>
  );
}

export function useAttributeTypeDetails() {
  return React.useContext(AttributeTypeDetailsContext);
}

export function useAttributeTypeDetailsDispatch() {
  return React.useContext(AttributeTypeDetailsDispatchContext);
}
