import JSONAPISerializer from 'json-api-serializer';

const serializer = new JSONAPISerializer({ unconvertCase: 'camelCase', convertCase: 'snake_case' });

serializer.register('attribute_types', {
  jsonapiObject: false,
});

serializer.register('asset_types', {
  jsonapiObject: false,
  relationships: {
    attributeTypes: { type: 'attribute_types' },
  },
});

// A custom schema that doesn't serialize attribute types
serializer.register('asset_types', 'shallow', {
  jsonapiObject: false,
});

export default serializer;
