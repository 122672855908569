import React from 'react';
import Avatar, { calculateAvatarColours } from '@paprika/card/lib/components/Avatar';
import Tokens from '@paprika/tokens';
import ArchiveIcon from '@acl-services/wasabicons/lib/Archive';

export default function LetterOrIconAvatar({ isArchived, name }: any) {
  const avatarColors = isArchived
    ? { backgroundColor: Tokens.color.blackLighten60, fontColor: Tokens.color.blackLighten20 }
    : calculateAvatarColours(name);

  const body = isArchived ? <ArchiveIcon data-qa-anchor="archive-icon" /> : name.substr(0, 1);

  return (
    <Avatar backgroundColor={avatarColors.backgroundColor} color={avatarColors.fontColor}>
      {body}
    </Avatar>
  );
}
