import React from 'react';
import Button from '@paprika/button';
import useI18n from '@paprika/l10n/lib/useI18n';
import {
  AttributeTypeDetailsFields,
  useAttributeTypeDetails,
  useAttributeTypeDetailsDispatch,
  ActionTypes,
} from 'providers/AttributeTypeDetailsProvider';

export default function SaveCancelButton({ setIsDirty }: { setIsDirty: (isDirty: boolean) => void }) {
  const { data, isSaving } = useAttributeTypeDetails();
  const dispatch = useAttributeTypeDetailsDispatch();
  const { t } = useI18n();

  function handleSave() {
    if (!data[AttributeTypeDetailsFields.name]) {
      dispatch({ type: ActionTypes.UPDATE_ERROR, payload: t('error.name_is_required') });
      return;
    }

    dispatch({ type: ActionTypes.UPDATE_SAVING_STATUS, payload: true });

    alert(
      `It is valid - save! Really call "saveAttribute()" in appState()
            name: ${data[AttributeTypeDetailsFields.name]}
            displayName: ${data[AttributeTypeDetailsFields.displayName]}
            tooltipText: ${data[AttributeTypeDetailsFields.tooltip]}
            isGlobal? ${data[AttributeTypeDetailsFields.isGlobal]}
            isRequired? ${data[AttributeTypeDetailsFields.isRequired]}
            isHidden?  ${data[AttributeTypeDetailsFields.isHidden]}
            `,
    );

    dispatch({ type: ActionTypes.UPDATE_SAVING_STATUS, payload: false });
    setIsDirty(false);
  }

  function handleCancel() {
    dispatch({ type: ActionTypes.CANCEL });
    setIsDirty(false);
  }

  return (
    <div>
      <Button
        data-qa-anchor="attribute-type-save-button"
        kind="primary"
        isPending={isSaving}
        onClick={handleSave}
        size="large"
      >
        {t('actions.save')}
      </Button>
      <Button data-qa-anchor="attribute-type-cancel-button" kind="minor" onClick={handleCancel} size="large">
        {t('actions.cancel')}
      </Button>
    </div>
  );
}
