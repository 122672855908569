import React from 'react';
import produce, { Draft } from 'immer';
import AssetTypeInterface from 'interfaces/AssetType';
import AttributeTypeInterface from 'interfaces/AttributeType';
import serializer from 'serializers';

import AssetsApi from 'services/AssetsApi';

export const AppStateContext = React.createContext<any>(null);
const AppStateDispatchContext = React.createContext<any>(null);

export enum ActionTypes {
  SET_ASSET_TYPES,
  SET_ASSET_TYPE_ATTRIBUTE_TYPES,
}

interface SetAssetTypesAction {
  type: ActionTypes.SET_ASSET_TYPES;
  payload: AssetTypeInterface[];
}

interface SetAssetsAttributeTypesAction {
  type: ActionTypes.SET_ASSET_TYPE_ATTRIBUTE_TYPES;
  payload: {
    id: string;
    data: AttributeTypeInterface[];
  };
}

type Action = SetAssetTypesAction | SetAssetsAttributeTypesAction;

const appStateReducer = produce((draft: Draft<Record<string, AssetTypeInterface>>, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_ASSET_TYPES:
      action.payload.forEach((assetType: AssetTypeInterface) => {
        if (draft && assetType.id in draft) {
          assetType.attributeTypes = draft[assetType.id].attributeTypes;
        } else {
          assetType.attributeTypes = null;
        }

        draft[assetType.id] = assetType;
      });

      break;
    case ActionTypes.SET_ASSET_TYPE_ATTRIBUTE_TYPES:
      if (!draft) return;

      const { id, data } = action.payload;
      draft[id].attributeTypes = data;
      break;
  }
});

export default function AppStateProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(appStateReducer, {});

  async function saveAssetType(id: string, name: string, displayName: string, description: string) {
    const isNew = id === 'new';
    const method = isNew ? 'POST' : 'PATCH';
    const url = isNew ? '/asset_types' : `/asset_types/${id}`;
    let error = '';
    let newId = undefined;

    const response = await AssetsApi.fetch(url, {
      method,
      body: serializer.serialize(
        'asset_types',
        {
          id: isNew ? undefined : id,
          name,
          displayName,
          description,
        },
        'shallow',
      ),
    });

    if (response.ok) {
      const assetType = serializer.deserialize('asset_types', await response.json());

      if (isNew) {
        assetType.attributeTypesCount = 0;
        assetType.attributeTypes = [];
        newId = assetType.id;
      }

      dispatch({ type: ActionTypes.SET_ASSET_TYPES, payload: [assetType] });
    } else {
      error = `${response.status}: ${response.statusText}`;
    }

    return {
      success: response.ok,
      id: newId,
      error,
    };
  }

  async function archiveAssetType(id: any) {
    let error = '';
    const response = await AssetsApi.fetch(`/asset_types/${id}/archive`, {
      method: 'PUT',
    });

    if (response.ok) {
      // TODO: this has to be updated in state... but is this response correct?
      const assetType = await response.json();
      dispatch({ type: ActionTypes.SET_ASSET_TYPES, payload: [assetType] });
    } else {
      error = `${response.status}: ${response.statusText}`;
    }

    return {
      success: response.ok,
      error,
    };
  }

  async function unArchiveAssetType(id: any) {
    let error = '';
    const response = await AssetsApi.fetch(`/asset_types/${id}/unarchive`, {
      method: 'PUT',
    });

    if (response.ok) {
      // TODO: this has to be updated in state... but is this response correct?
      const assetType = await response.json();
      dispatch({ type: ActionTypes.SET_ASSET_TYPES, payload: [assetType] });
    } else {
      error = `${response.status}: ${response.statusText}`;
    }

    return {
      success: response.ok,
      error,
    };
  }

  return (
    <AppStateDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider
        value={{
          assetTypes: state,
          hasAssetTypes: Object.keys(state).length > 0,
          saveAssetType,
          archiveAssetType,
          unArchiveAssetType,
        }}
      >
        {children}
      </AppStateContext.Provider>
    </AppStateDispatchContext.Provider>
  );
}

export function useAppState() {
  return React.useContext(AppStateContext);
}

export function useAppStateDispatch() {
  return React.useContext(AppStateDispatchContext);
}
