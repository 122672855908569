import React from 'react';
import Input from '@paprika/input';
import useToggle from '../../../../../useToggle';
import Checkbox from '@paprika/checkbox';
import useI18n from '@paprika/l10n/lib/useI18n';
import { text } from '../../../../../types';

export interface TextProps {
  onChange(type: text): void;
  defaultValue: string;
}

export default function Paragraph({ onChange, defaultValue }: TextProps) {
  const [isToggle, handleToggle] = useToggle(Boolean(defaultValue));
  const [value, setValue] = React.useState(defaultValue);
  const { t } = useI18n();

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    setValue(event.currentTarget.value);
  }

  React.useEffect(() => {
    const response: text = {
      default: !value || value === '' ? undefined : value,
      type: 'text',
    };

    onChange(response);
  }, [onChange, value]);

  return (
    <>
      <div className="widget-space">
        <Checkbox checkedState={useToggle.toCheckBox(isToggle)} onChange={handleToggle}>
          {t('attribute_types_details.widgets.set_default_values')}
        </Checkbox>
      </div>

      {isToggle ? (
        <div className="widget-space">
          <Input value={value} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
}
