import React from 'react';
import Button from '@paprika/button';
import useI18n from '@paprika/l10n/lib/useI18n';
import './RemoveAssetType.scss';

export default function RemoveAssetType() {
  const { t } = useI18n();

  return (
    <Button className="attribute-types__footer-buttons__remove-asset-type" size="large">
      {t('attribute_type.remove_from_asset_type')}
    </Button>
  );
}
