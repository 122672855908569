import React from 'react';

export default function useValue(defaultValue: any) {
  const [value, setValue] = React.useState(defaultValue);

  // this can be use as event handler or as as assigning the value directly
  // therefore the any type value
  function handleChange(event: React.FormEvent<HTMLInputElement> | any) {
    if (typeof event === 'object' && 'type' in event) {
      setValue(event.currentTarget.value);
      return;
    }

    setValue(event);
  }

  return [value, handleChange] as const;
}
