import React from 'react';

import './SideNavigationItem.scss';

type Props = {
  displayName: string;
  url: string;
  isActive: boolean;
  key: string;
  children: React.ReactNode;
};

function SideNavigationItem(props: Props) {
  const { displayName, url, children, isActive } = props;
  const activeClass = isActive ? 'active' : '';

  return (
    <div className={`side-navigation-item ${activeClass}`}>
      {children}
      <a className={`side-navigation-item__link ${activeClass}`} href={url}>
        {displayName}
      </a>
    </div>
  );
}

export default SideNavigationItem;
