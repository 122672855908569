import React from 'react';
import AssetTypes from './AssetTypes';
import AttributeTypes from './AttributeTypes';

export default function HorrendousUI() {
  return (
    <>
      <h1>[Horrendous UI]</h1>
      <AssetTypes />
      <br />
      <AttributeTypes />
    </>
  );
}
