import React from 'react';
import './DataGridHeader.scss';

interface DataGridHeaderProps {
  Icon: any;
  label: string;
}

export default function DataGridHeader({ Icon, label }: DataGridHeaderProps) {
  return (
    <span className="asset-type-attribute-types-page__data-grid-header">
      {Icon}
      {label}
    </span>
  );
}
