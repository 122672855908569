import React from 'react';
import Confirmation from '@paprika/confirmation';
import useI18n from '@paprika/l10n/lib/useI18n';
import ArchiveIcon from '@acl-services/wasabicons/lib/Archive';
import './ArchiveButton.scss';

export default function ArchiveButton() {
  const { t } = useI18n();

  return (
    <Confirmation
      align="top"
      body={t('attribute_type.archived_warning')}
      confirmLabel={t('actions.archive')}
      edge="right"
      maxWidth={296}
      offset={0}
      onConfirm={() => null}
      zIndex={8}
    >
      <Confirmation.TriggerButton
        data-qa-anchor="attribute-type-archived-trigger"
        className="attribute-types__footer-buttons__archive-button"
        icon={<ArchiveIcon />}
        size="large"
      />
    </Confirmation>
  );
}
