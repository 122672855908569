import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useI18n from '@paprika/l10n/lib/useI18n';

interface FooterCopyProps {
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
  locale: string;
}

export default function FooterCopy({ createdBy, updatedBy, updatedAt, locale }: FooterCopyProps) {
  const { t } = useI18n();
  return (
    <div className="overview-panel__footer-copy">
      <p>{t('asset_type.created_by', { name: createdBy })}</p>
      <p>
        {t('asset_type.updated_by', {
          name: updatedBy,
          when: moment(updatedAt).locale(locale).fromNow(),
        })}
      </p>
      <Link to="activity-log">{t('asset_type.activity_log_link')}</Link>
    </div>
  );
}
