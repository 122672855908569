import React from 'react';
import CheckIcon from '@acl-services/wasabicons/lib/Check';
import './IsGlobalContent.scss';

interface IsGlobalContentProps {
  isChecked?: boolean;
}

export default function IsGlobalContent({ isChecked }: IsGlobalContentProps) {
  if (!isChecked) return null;

  return (
    <div className="asset-type-attribute-types-page__is-global-content">
      <CheckIcon />
    </div>
  );
}
