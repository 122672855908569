import React from 'react';
import Checkbox from '@paprika/checkbox';
import FormElement from '@paprika/form-element';
import Input from '@paprika/input';
import { ShirtSizes } from '@paprika/helpers/lib/customPropTypes';
import FormElementContentProps from 'interfaces/FormElementContentProps';

import './CheckboxWithInput.scss';

export default function CheckboxWithInput({
  text,
  onChange,
  isExpanded,
  onToggleIsExpanded,
  error,
  isSaving,
  inputLabel,
  placeholder,
  checkboxLabel,
  helpText,
  dataTestId,
}: any) {
  const checkboxState = isExpanded ? Checkbox.types.state.CHECKED : Checkbox.types.state.UNCHECKED;

  function renderPrompt() {
    if (!isExpanded) {
      return null;
    }

    return (
      <>
        <FormElement isLabelVisuallyHidden label={inputLabel} size={ShirtSizes.LARGE}>
          <FormElement.Content>
            {({ idForLabel, ariaDescribedBy }: FormElementContentProps) => (
              <Input
                autoFocus
                id={idForLabel}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.value);
                }}
                value={text}
                placeholder={placeholder}
                aria-describedby={ariaDescribedBy}
                aria-required
                hasError={!!error}
                size={ShirtSizes.LARGE}
                isDisabled={isSaving}
                data-qa-anchor={dataTestId}
              />
            )}
          </FormElement.Content>
          {error && <FormElement.Error>{error}</FormElement.Error>}
        </FormElement>
        {helpText && <div className="checkbox-with-input__help-text">{helpText}</div>}
      </>
    );
  }

  return (
    <div className="checkbox-with-input">
      <Checkbox
        checkedState={checkboxState}
        onChange={() => {
          onToggleIsExpanded((oldState: boolean) => !oldState);
        }}
        isDisabled={isSaving}
      >
        {checkboxLabel}
      </Checkbox>
      {renderPrompt()}
    </div>
  );
}
