const protocol = window.location.protocol;
const region = process.env.REACT_APP_REGION !== 'us' ? `-${process.env.REACT_APP_REGION}` : '';
const domain = process.env.REACT_APP_ROOT_HOST;

let assetsApiUrl = `${protocol}//asset-types-api${region}.${domain}`;
let workflowsApiUrl = `${protocol}//workflows${region}.${domain}`;
let loginUrl = `${protocol}//accounts.${domain}/login`;
let tokenRefreshUrl = `${protocol}//accounts.${domain}/api/token/refresh`;
let launchpadApiUrl = `${protocol}//apis${region}.${domain}/accounts/api`;

const isLocalhost = !domain;
const isLocal = window.location.origin.match(/\.local$/);
const isRunningSpa = window.location.port === '3000';

if (isLocalhost) {
  const localhostUrl = `${protocol}//${window.location.hostname}:3030`;

  assetsApiUrl = localhostUrl;
  workflowsApiUrl = localhostUrl;
  loginUrl = `${protocol}//${window.location.host}/login`;
  tokenRefreshUrl = `${localhostUrl}/login/refresh`;
  launchpadApiUrl = localhostUrl;
}

if (isLocal) {
  launchpadApiUrl = `${protocol}//apis.${domain}:9001/accounts/api`;
}

if (isRunningSpa) {
  const jsonServerUrl = `http://localhost:9010`;

  assetsApiUrl = jsonServerUrl;
  workflowsApiUrl = jsonServerUrl;
  tokenRefreshUrl = `${jsonServerUrl}/login/refresh`;
  launchpadApiUrl = jsonServerUrl;
}

export const AppConfig = {
  assetsApiUrl,
  workflowsApiUrl,
  loginUrl,
  tokenRefreshUrl,
  launchpadApiUrl,
};
