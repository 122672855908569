import React from 'react';
import FormElement from '@paprika/form-element';
import Button from '@paprika/button';
import AssetsApi from 'services/AssetsApi';
import WorkflowsApi from 'services/WorkflowsApi';
import serializer from 'serializers';

import './AssetTypes.scss';

interface WorkflowAttributes {
  name: string;
  api_name: string;
}

export interface Workflow {
  id: string;
  attributes: WorkflowAttributes;
}

export function useWorkflows(setError: (value: string) => void) {
  const [workflows, setWorkflows] = React.useState<any>();

  React.useEffect(() => {
    const getWorkflows = async () => {
      let response = await WorkflowsApi.fetch('/workflows', { method: 'GET' });
      const res = await response.json();
      if (response.ok) {
        setWorkflows(res['data']);
      } else {
        setError(`${response.status}: ${response.statusText} - ${res.errors[0].detail}`);
      }
    };
    getWorkflows();
  }, []);

  return workflows;
}

export function workflowName(workflows: Array<Workflow>, workflowId: string): string {
  const workflow = getWorkflow(workflows, workflowId);
  if (!workflow) {
    return '';
  }
  return workflow.attributes.name;
}

export function getWorkflow(workflows: Array<Workflow>, workflowId: string): Workflow {
  return (workflows || []).filter((workflow) => workflow.id === workflowId)[0];
}

export default function AttributeTypes() {
  const [assetType, setAssetType] = React.useState<any>();
  const [assetTypes, setAssetTypes] = React.useState<any>();
  const [error, setError] = React.useState('');
  const workflows = useWorkflows(setError);
  if (workflows && workflows[0] && (assetType === undefined || !assetType.workflowId)) {
    const noWorkflow = workflows.filter((workflow: Workflow) => workflow.attributes.api_name === 'no_workflow')[0];

    setAssetType((prevState: Record<string, unknown>) => ({
      ...prevState,
      workflowId: noWorkflow?.id || workflows[0].id,
    }));
  }
  const generateAssetType = () => ({
    name: assetType && assetType.name ? assetType.name : `vendors-${Math.ceil(Math.random() * 1000000)}`,
    description: assetType?.description || '',
    displayName: assetType?.displayName || '',
    workflowId: assetType?.workflowId,
  });

  const createAssetType = async () => {
    let response = await AssetsApi.fetch('/asset_types', {
      method: 'POST',
      body: serializer.serialize('asset_types', generateAssetType()),
    });
    const res = await response.json();
    if (response.ok) {
      setAssetType(serializer.deserialize('asset_types', res));
    } else {
      setError(`${response.status}: ${response.statusText} - ${res.errors[0].detail}`);
    }
  };

  const sortedAssetTypes = (assetTypes: any) => {
    return assetTypes.sort((a: { id: string }, b: { id: string }) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
  };

  const listAssetTypes = async () => {
    let response = await AssetsApi.fetch('/asset_types?include=attribute_types', {
      method: 'GET',
    });
    if (response.ok) {
      let types = serializer.deserialize('asset_types', await response.json());

      let sorted = sortedAssetTypes(types);
      setAssetTypes(sorted);
    } else {
      setError(`${response.status}: ${response.statusText}`);
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setAssetType((prevState: Record<string, unknown>) => ({
      ...prevState,
      name: newValue,
    }));
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setAssetType((prevState: Record<string, unknown>) => ({
      ...prevState,
      description: newValue,
    }));
  };

  const onDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setAssetType((prevState: Record<string, unknown>) => ({
      ...prevState,
      displayName: newValue,
    }));
  };

  const onWorkflowChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setAssetType((prevState: Record<string, unknown>) => ({
      ...prevState,
      workflowId: newValue,
    }));
  };

  const renderWorkflowOption = (workflow: any) => {
    return (
      <option key={workflow.id} value={workflow.id} selected={workflow.attributes.api_name === 'no_workflow'}>
        {workflow.attributes.name}
      </option>
    );
  };

  return (
    <>
      <h2>Asset Types</h2>
      <p>Welcome to Asset type creation</p>
      <FormElement label="Name" isInline>
        <FormElement.Content>
          {({ idForLabel }: { idForLabel: string }) => (
            <input id={idForLabel} data-qa-anchor="name-input" onChange={onNameChange} />
          )}
        </FormElement.Content>
      </FormElement>
      <FormElement label="Description" isInline>
        <FormElement.Content>
          {({ idForLabel }: { idForLabel: string }) => (
            <input id={idForLabel} data-qa-anchor="description-input" onChange={onDescriptionChange} />
          )}
        </FormElement.Content>
      </FormElement>
      <FormElement label="DisplayName" isInline>
        <FormElement.Content>
          {({ idForLabel }: { idForLabel: string }) => (
            <input id={idForLabel} data-qa-anchor="display-name-input" onChange={onDisplayNameChange} />
          )}
        </FormElement.Content>
      </FormElement>

      <FormElement label="Workflow" isInline>
        <FormElement.Content>
          {({ idForLabel }: { idForLabel: string }) => (
            <select id={idForLabel} data-qa-anchor="workflow-select" onChange={onWorkflowChange}>
              {workflows && workflows.map((workflow: any) => renderWorkflowOption(workflow))}
            </select>
          )}
        </FormElement.Content>
      </FormElement>
      <br />
      <div>
        <Button kind="primary" data-qa-anchor="create-asset-type-button" onClick={createAssetType}>
          Create Asset Type
        </Button>
        <Button kind="primary" onClick={listAssetTypes}>
          List All Asset Types
        </Button>
      </div>
      {assetType && assetType.id ? (
        <Button.Link
          kind="flat"
          href={`/horrendous-ui/asset_types/${assetType.id}`}
          data-qa-anchor="new-asset-type-link"
          isOpenNewTab={false}
        >
          View {assetType.name}
        </Button.Link>
      ) : error ? (
        error
      ) : null}
      {assetTypes && (
        <div className="assets-list">
          <h2>Asset Types List</h2>
          <div className="asset-types-grid">
            {assetTypes.map((assetType: any) => (
              <a key={assetType.name} href={`/horrendous-ui/asset_types/${assetType.id}`}>
                <div className="asset-type-card">
                  <h3>{assetType.name}</h3>
                  <span>{assetType.description}</span>
                  <span>
                    {assetType.attributeTypes.length} attribute{' '}
                    {assetType.attributeTypes.length === 1 ? 'type' : 'types'}
                  </span>
                  <small>
                    <span>
                      <strong>Workflow name:</strong> {workflowName(workflows, assetType.workflowId)}
                    </span>
                    <span>
                      <strong>Created by:</strong> {assetType.createdBy}
                    </span>
                    <span>
                      <strong>Updated by:</strong> {assetType.updatedBy}
                    </span>
                  </small>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
