import React from 'react';
import Heading from '@paprika/heading';
import useI18n from '@paprika/l10n/lib/useI18n';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSession } from 'providers/SessionProvider';
import getLocale from 'lib/helpers/getLocales';

import './RecentActivity.scss';

interface Activity {
  createdAt: string;
  createdBy: string;
  editedAt: string;
  url: string;
  editedBy: string;
}

export default function RecentActivity({ activity }: { activity: Activity }) {
  const { createdAt, createdBy, editedAt, editedBy, url } = activity;

  const { t } = useI18n();
  const { locale } = useSession();
  const sessionLocale = getLocale(locale);

  function getFormattedDate(actionAt: string) {
    return moment(actionAt).locale(sessionLocale).format('L');
  }

  return (
    <>
      <Heading level={3} className="recent-activity">
        {t('attribute_type.recent_activity')}
      </Heading>
      <p data-qa-anchor="sidepanel-recent-activity-edited-by">
        {t('attribute_type.edited_by', {
          name: editedBy,
          date: getFormattedDate(editedAt),
          interpolation: { escapeValue: false },
        })}
      </p>
      <p data-qa-anchor="sidepanel-recent-activity-created-by">
        {t('attribute_type.created_by', {
          name: createdBy,
          date: getFormattedDate(createdAt),
          interpolation: { escapeValue: false },
        })}
      </p>
      <Link to={url}>{t('asset_type.activity_log_link')}</Link>
    </>
  );
}
